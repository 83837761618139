<template>
<div>
    <CQSEditor/>
    <CQSDeletor/>
    <b-card
      no-body
      style="        
        margin-top: 0px;
        margin-bottom: 20px;
        background-color: whitesmoke;
      "
      title="Filter"
    >
      <h5>{{ $t('Scope Parameters') }}</h5>
       
      <div class="d-flex flex-row justify-content-left baseline fcontainer" style="align-items: center">
          <b-dropdown id="dropdown-form"
                      :text="timeRangeOptions.find(p => p.value === timeRange) ? timeRangeOptions.find(p => p.value === timeRange).text : timeRangeOptions[0].text"
                      ref="dropdown_timeRange"
                      class="m-2 fitem">
              <b-dropdown-item v-for="item of timeRangeOptions"
                               v-bind:key="item.text"
                               @click="timeRange = item.value"
                               href="#">{{item.text}}</b-dropdown-item>
          </b-dropdown>
          <span class="m-2 text-10 fitem"  v-if="timeRange == 8">{{ $t('From') }}</span>
          <b-form-datepicker id="start-datepicker"
                             class="m-2 text-10"
                             :locale="$i18n.locale"
                             v-model="customeStart"
                             v-if="timeRange == 8"></b-form-datepicker>
          
          <label for="end-datepicker" class="m-2 text-10"  v-if="timeRange == 8">{{ $t('to') }}</label>
          <b-form-datepicker id="end-datepicker"
                             class="m-2 text-10 fitem"
                             :locale="$i18n.locale"
                             v-model="customeEnd"
                             v-if="timeRange == 8"></b-form-datepicker>

          <span class="m-2 text-10 fitem"  v-if="timeRange != 8">{{ $t('From') }} {{new Date(customeStart).toLocaleDateString($i18n.locale)}} {{ $t('to') }} {{new Date(customeEnd).toLocaleDateString($i18n.locale)}}</span>                             

          <div style="flex-basis: 100%; height: 0;" v-if="$store.state.user.isMobile"/>
          <b-dropdown id="dropdown-form"
                      :text="scopeOptions.find(p => p.value === scope) ? scopeOptions.find(p => p.value === scope).text : scopeOptions[0].text"
                      ref="dropdown_scope"
                      class="m-2 fitem">
              <b-dropdown-item v-for="item of scopeOptions"
                               v-bind:key="item.text"
                               @click="scope = item.value"
                               href="#">{{item.text}}</b-dropdown-item>
          </b-dropdown>
          
          <b-dropdown id="dropdown-form" v-if="scope === 1"
                      :text="$t('Care Unit Filter')"
                      ref="dropdown"
                      class="m-2 fitem">
              <b-dropdown-form>                  
                  <template v-for="rs in mRoomsetFilter">                      
                          <b-form-checkbox 
                                           class="m-2"
                                           :key="rs.oid"
                                           v-model="rs.value"
                                           @change="dirtyFilter=true">{{ rs.label }}</b-form-checkbox>                      
                  </template>
              </b-dropdown-form>
          </b-dropdown>

          <b-dropdown id="dropdown-form" v-if="scope === 2"
                      :text="$t('Room Filter')"
                      ref="dropdown"
                      class="m-2 fitem">
              <b-dropdown-form>                  
                  <template v-for="room in mRoomFilter">                      
                      <b-form-checkbox class="m-2" :key="room.oid" v-model="room.value"  @change="dirtyFilter=true">
                          {{ room.label }}
                      </b-form-checkbox>                      
                  </template>                                  
              </b-dropdown-form>
          </b-dropdown>

<!--
          <b-dropdown id="dropdown-form"
                      :text="$t('Visualization settings')"
                      ref="dropdown"
                      class="m-2 fitem" v-if="false">
              <b-row><b-col cols="6"><b-button @click="changeAllStatusSelected(true)">{{$t('All')}}</b-button></b-col><b-col cols="6"><b-button @click="changeAllStatusSelected(false)">{{$t('None')}}</b-button></b-col></b-row>                  
                  <b-form-checkbox class="m-2" v-model="showChanges" @change="dirtyFilter=true">{{ $t('Show Changes') }}</b-form-checkbox>
                  <b-form-checkbox class="m-2" v-model="showTrends" @change="dirtyFilter=true">{{ $t('Show Trends') }}</b-form-checkbox>
                  <b-form-checkbox class="m-2" v-model="showRuns" @change="dirtyFilter=true">{{ $t('Show Runs') }}</b-form-checkbox>
                  <b-form-checkbox class="m-2" v-model="showOutlier" @change="dirtyFilter=true">{{ $t('Show Outliers') }}</b-form-checkbox>
                                
          </b-dropdown> 
-->

          <b-button class="m-auto m-2 fitem" v-if="dirtyFilter" variant="primary" @click="computeRunningChart()">
            {{ $t('Apply')}}
          </b-button>


      </div>

     

    </b-card>
    <b-card>
       <apexchart v-if="paqsOptions.chart"
                               type="line"
                               height="400"
                               :options="paqsOptions"
                               :series="paqsSeries" />
    </b-card>

  <b-card>
    <ul>
      <li v-if="changes.count > 0"><span >{{changes.length}} {{$t('changes has been detected')}}</span></li>
      <li v-if="trends.count > 0"><span >{{changes.length}} {{$t('trends has been detected')}}</span></li>
      <li v-if="runs.count > 0"><span >{{runs.length}} {{$t('changes has been detected')}}</span></li>
      <li v-if="runsEval"><span >{{runsEval}}</span></li>
    </ul>
  </b-card>

    <b-card>
      <b-btn @click="createCQS" variant="primary" id="send_create_action">
          <font-awesome-icon icon="plus"  />
          {{ $t('Create Action') }}
      </b-btn>    
     <b-table v-if="Array.isArray($store.state.fleet.cqs)"
             ref="table"
             :busy="isLoading" 
             hover 
             sm 
             borderless                                         
             responsive
             light 
             class="w-auto" 
             style="margin-bottom: 4rem;"
             :items="filteredCQSList"    
             :fields="fields"                      
             >       
             <template v-slot:cell(scope)="row">     
               <div class="d-flex flex-row">          
                <span class="mr-auto p-2" v-if="row.item.roomOid && row.item.roomsetOid">{{ $t('Room') + ': ' + row.item.roomsetLabel + '/' + row.item.roomLabel }}</span>
                <span class="mr-auto p-2" v-if="!row.item.roomOid && row.item.roomsetOid">{{ $t('Care Unit') + ': ' + row.item.roomsetLabel  }}</span>
                <span class="mr-auto p-2" v-if="!row.item.roomOid && !row.item.roomsetOid">{{ $t('Organization') + ': ' + row.item.organizationLabel  }} </span>
                </div>
            </template>     

          <template v-slot:cell(date)="row">
            <div class="d-flex flex-row">
                <div class="mr-auto p-2">{{ moment(row.item.date).format('YYYY-MM-DD') }}</div>                                
            </div>
          </template>

          <template v-slot:cell(title)="row">
            <div class="d-flex flex-row">
                <div class="mr-auto p-2">{{ row.item.title }}</div>
                
                <b-dropdown  size="lg" dropleft  variant="link" toggle-class="text-decoration-none" no-caret class="p-2" >
                    <template #button-content>
                        <font-awesome-icon icon="ellipsis-v" />
                    </template>                                        
                    <b-dropdown-item class="p-2" @click="editCQS(row.item)"> <font-awesome-icon icon="wrench" />{{ $t('Edit...') }}</b-dropdown-item>
                    <b-dropdown-item class="p-2" @click="deleteCQS(row.item)"> <font-awesome-icon icon="user-slash" />{{ $t('Delete Action') }}</b-dropdown-item>                  
                </b-dropdown>
            </div>
          </template>
     </b-table>
    </b-card>
    </div>
</template>

<script>
import moment from 'moment';
import CQSEditor from './CQSEditor';
import CQSDeletor from './CQSDeletionModal';

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "PAQS"
  },
  props: {
    
  },
  components: {
    CQSEditor,
    CQSDeletor,
  },
  data() {
    return {    
      moment,  
      isLoading: false,

      fields: [
        { key: "date", label: this.$t('Date'), sortable: true },
        { key: "title", label: this.$t('Title'), sortable: true },
        { key: "scope", label: this.$t('Scope'),sortable: false },        
      ],
      
      scopeChoice: 0,
      timeRangeOptions: [               
        { value: 5, text: this.$t("Last month") },
        { value: 6, text: this.$t("Last 3 months") },
        { value: 7, text: this.$t("Last 6 months") },
        { value: 8, text: this.$t("Custom") },
      ],
      scopeOptions: [
          { value: 0, text: this.$t('All Care Units')},
          { value: 1, text: this.$t('Care Units')},
          { value: 2, text: this.$t('Room')}
          ],
      zzscope: 0,

      mAllRoomSelected: false,
      mRoomFilter: [],
      mRoomsetFilter: [],
      
      paqsOptions: {},
      paqsSeries: {},


      showChanges: true,
      showTrends: true,
      showRuns: true,
      showOutlier: true,
      
      dirtyFilter: false,
      room2roomset: {},

      changes: [],
      runs: [],
      runsEval: '',
      trends: [],
      outliers: [],

    };
  },
  computed: {

    scope: {
      get: function() { return this.zzscope; },
      set: function(val) { this.zzscope = val; },
    },

    timeRange: {
      // getter
      get: function () {
        return this.$store.state.fleet.filter.timeRangeChoice;
      },
      // setter
        set: function (newValue) {            
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.timeRangeChoice = newValue;
        let nStart = moment();
        let nEnd = moment();
        if(![0,1,2,3,4,5,6,7,8].includes(newValue))
          newValue = 0;
        if (newValue === 0) {
          nStart = moment().add(-7, "days").toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 1) {
          nStart = moment().startOf("week").toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 2) {
          nStart = moment()
            .add(-7, "days")
            .startOf("week")
            .toISOString();
          nEnd = moment().startOf("week").toISOString();
        }
        if (newValue === 3) {
          nStart = moment()
            .add(-30, "days")
            .toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 4) {
          nStart = moment()
            .startOf("month")
            .toISOString();
          nEnd = moment().endOf("week").toISOString();
        }
        if (newValue === 5) {
          nStart = moment()
            .add(-1, "months")
            .startOf("months")
            .toISOString();
          nEnd = moment()
            .add(-1, "months")
            .endOf("months")
            .toISOString();
        }
        if (newValue === 6) {
          nStart = moment()
            .add(-3, "months")
            .toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 7) {
          nStart = moment()
            .add(-6, "months")
            .toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 8) {
          nStart = moment().add(-7, "days").toISOString();
            nEnd = moment().toISOString();
        }
        filter.dateFilter.start = nStart;
        filter.dateFilter.end = nEnd;


        filter.showFall = true;
        filter.showBedExit = false;
        filter.showND = true;
        filter.showFA = false;
        filter.showTD = true;
        filter.showUN = false;
        filter.showTEST = false;        
        filter.roomFilterValues = [];

        for(const rs of this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization && p.label.charAt(0) !== '_'))
          for(const r of rs.roomList)
            filter.roomFilterValues.push([r.oid, r.externalID]);

        this.$store.dispatch("fleet/changeFilter", filter).then(() => {
          this.computeRunningChart(); 
        });
        
      },
    },

    customeStart: {
      get: function() { 
        let v = this.$store.state.fleet.filter.dateFilter.start;        
        return  v;
        },
      set: function(val) { }
    },

    customeEnd: {
      get: function() {return this.$store.state.fleet.filter.dateFilter.end },
      set: function(val) { }
    },

    filteredCQSList() {
      
      switch (this.zzscope) {
          case 0: { // all care units / organization
            return this.$store.state.fleet.cqs.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization)
          }
          case 1: { // specific care unit
              let rsoid = this.mRoomsetFilter.filter(p => p.value).map( p => p.oid);
              return this.$store.state.fleet.cqs.filter(p => rsoid.includes(p.roomsetOid))
          }          
          case 2: { // specific rooms
               let roid = this.mRoomsetFilter.filter(p => p.value).map( p => p.oid);
              return this.$store.state.fleet.cqs.filter(p => roid.includes(p.roomOid))
          }
      }
      
      
      return []
    },
  },
  mounted() {   
    this.computeRunningChart(); 
    this.isLoading = true;
    const self = this;    
    let i = 0;

    
    for (const rs of this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization && p.label.charAt(0) !== '_'))      
    {
      this.mRoomsetFilter.push({ oid: rs.oid, value: i === 0, label: rs.label});
      i++;
      for (const room of rs.roomList)    {
        this.room2roomset[room.oid] = rs.oid;           
            if(!this.mRoomFilter.find(r => room.oid === r.oid))
              this.mRoomFilter.push({ oid: room.oid, rsoid: rs.oid, value: true, label: rs.label + '/' + room.label})
      }
            
      }      

      this.$store.dispatch('fleet/loadCQS').finally(() => {
      this.isLoading = false;
    });
    
  },
  methods: {



    getRunMinMax(count) {
        if(count < 10) return null;
        switch(count) {
            case 10: return { min: 3  , max: 8 }
            case 11: return { min: 3  , max: 9 }
            case 12: return { min: 3  , max: 10 }
            case 13: return { min: 4  , max: 10 }
            case 14: return { min: 4  , max: 11 }
            case 15: return { min: 4  , max: 12 }
            case 16: return { min: 5  , max: 12 }
            case 17: return { min: 5  , max: 13 }
            case 18: return { min: 6  , max: 13 }
            case 19: return { min: 6  , max: 14 }
            case 20: return { min: 6  , max: 15 }
            case 21: return { min: 7  , max: 15 }
            case 22: return { min: 7  , max: 16 }
            case 23: return { min: 8  , max: 16 }
            case 24: return { min: 8  , max: 17 }
            case 25: return { min: 9  , max: 17 }
            case 26: return { min: 9  , max: 18 }
            case 27: return { min: 9  , max: 19 }
            case 28: return { min: 10 , max:  19 }
            case 29: return { min: 10 , max:  20 }
            case 30: return { min: 11 , max:  20 }
            case 31: return { min: 11 , max:  21 }
            case 32: return { min: 11 , max:  22 }
            case 33: return { min: 11 , max:  22 }
            case 34: return { min: 12 , max:  23 }
            case 35: return { min: 13 , max:  23 }
            case 36: return { min: 13 , max:  24 }
            case 37: return { min: 13 , max:  25 }
            case 38: return { min: 14 , max:  25 }
            case 39: return { min: 14 , max:  26 }
            case 40: return { min: 15 , max:  26 }
            case 41: return { min: 16 , max:  26 }
            case 42: return { min: 16 , max:  27 }
            case 43: return { min: 17 , max:  27 }
            case 44: return { min: 17 , max:  28 }
            case 45: return { min: 17 , max:  29 }
            case 46: return { min: 17 , max:  30 }
            case 47: return { min: 18 , max:  30 }
            case 48: return { min: 18 , max:  31 }
            case 49: return { min: 19 , max:  31 }
            case 50: return { min: 19 , max:  32 }
        }
        return null;
    },

    computeRunningChart() {
      if(!this.$store.state.fleet.filteredAlertList || this.$store.state.fleet.filteredAlertList.length === 0) return;
      
      const perWeek = {};
        let minW = moment();
        let maxW = moment().add(-2,'years');
        let alerts = [];
        
        switch (this.zzscope) {
          case 0: { // all care units / organization
            alerts = this.$store.state.fleet.filteredAlertList.filter(a => a.organizationLabel === this.$store.state.fleet.currentOrganization);
            break;
          }
          case 1: { // specific care unit
              let rsoid = this.mRoomsetFilter.filter(p => p.value).map( p => p.oid);
              alerts = this.$store.state.fleet.filteredAlertList.filter(a => rsoid.includes(this.room2roomset[a.roomOID]));
              break;
          }          
          case 2: { // specific rooms
               let roid = this.mRoomFilter.filter(p => p.value).map( p => p.oid);              
              alerts = this.$store.state.fleet.filteredAlertList.filter(a => roid.includes(a.roomOID));
              break;
          }
      }

        for (const a of alerts) {
            const m = moment(a.date).year() * 100 + moment(a.date).isoWeek();
            if (moment(a.date) > maxW) maxW = moment(a.date);
            if (moment(a.date) < minW) minW = moment(a.date);


            if (!perWeek[m]) perWeek[m] = { be: 0, f: 0 };
            if (a.type === 5) perWeek[m].f++;
            if (a.type === 4) perWeek[m].be++;            
        }

        
        let y = [];      
        const series = [
          { name: this.$t('Falls'), data: []},
          { name: this.$t('Median'), data: []},
        ]
        
        let ld = minW.clone(); 
        let yd = minW.clone(); 
        while(yd < maxW) 
        {
            yd = yd.clone().add(1, 'day');                                                       
            y.push(yd.format('YYYY-MM-DD'));
        }
        let weekserie = [];
        let weekMedian = [];
        let weekDates = [];
        while(ld < maxW) 
        {            
            let mld = (moment(ld).year() * 100) + ld.isoWeek();
            let nld = (moment(ld).year() * 100) + (ld).isoWeek() + 1;
            console.log(ld.format('YYYY-MM-DD') + ' --> ' + mld + ' week = '  + ld.isoWeek())            
           // y.push(ld.format('YYYY-MM-DD'));

          
            let falls = perWeek[mld]  ? perWeek[mld].f : null;
            let nextFall = perWeek[nld]  ? perWeek[nld].f : null;
            weekserie.push(falls || 0);
            weekDates.push(ld);
            for(let i = 0; i < 7; i++)                  
              series[0].data.push(falls + i * (nextFall - falls) / 7 );            
            ld = ld.clone().add(1, 'week');
        }
        let medianS = weekserie.concat().sort((a,b) => a - b);
        let median = medianS[Math.floor(medianS.length/2)];
        for(let i of series[0].data) {
          series[1].data.push(median);
        }
        for(let i of weekserie) {
          weekMedian.push(median);
        }

      // PAQS detection part
      // detect changes
      let currentChange = null;

      let changeSerie = [];      
      for(let i = 0; i < weekserie.length; i++){
        if(weekserie[i] !== weekMedian[i])
            changeSerie.push({ t: i, v: weekserie[i], m: weekMedian[i]});
      }

      let changes = [];
      const CHANGE_MINSAMPLE = 4;
      
      for(let tt = 1; tt < changeSerie.length; tt++)
      {
        let pv = changeSerie[tt-1];
        let cv = changeSerie[tt];

        
        if(cv.v > cv.m && pv.v < pv.m || cv.v < cv.m && pv.v > pv.m) // local change here
        {
          if(!currentChange) // we have a new change
          {
              currentChange = {
                t: cv.t,
                start: weekDates[cv.t-1].format('YYYY-MM-DD'),
                count: 1,
                direction: cv.v > cv.m // true = up, false = down
              }
          } else { // we are breaking a change
              if(currentChange.count >= CHANGE_MINSAMPLE) { // that was a change!
                currentChange.end = weekDates[cv.t-1].format('YYYY-MM-DD')
                changes.push(changes)
              }
              currentChange = null;
          }
        } else { // stable over or under
          if(currentChange) {
            currentChange.count++;
          }
        }
      }
      if(currentChange && currentChange.count >= CHANGE_MINSAMPLE) { // that was a change!
          currentChange.end = weekDates[weekDates.length-1].format('YYYY-MM-DD')
          changes.push(currentChange)
      }
      


      // detect trends
      let currentTrend = null;
      const TREND_MINSAMPLE = 5;
      let trends = [];
      for(let i = 1; i < weekserie.length; i++){
        console.log('at ' + i + ' ==> ' + (weekserie[i] > weekserie[i-1] ? 'up' : 'down'));
        if(currentTrend) {
          if(currentTrend.direction) {
              // current val should be upper, else we break trend
              if(weekserie[i] >= weekserie[i-1]) {
                  if(weekserie[i] > weekserie[i-1])
                      currentTrend.count++;                
              } else { // break trend
                if(currentTrend.count >= TREND_MINSAMPLE) {
                  currentTrend.tend = i-1;
                  trends.push(currentTrend)
                }
                currentTrend = null;
              }
          } else {
              // current val should be lower, else we break trend
              if(weekserie[i] <= weekserie[i-1]) {
                  if(weekserie[i] < weekserie[i-1])
                    currentTrend.count++;                
              } else { // break trend
                if(currentTrend.count >= TREND_MINSAMPLE) {
                  currentTrend.tend = i-1;
                  trends.push(currentTrend)
                }
                currentTrend = null;
              }
          }        
        } 
        if(!currentTrend)
         {
            if(weekserie[i] > weekserie[i-1]) {
                currentTrend = { 
                    tstart: i,                    
                    start: weekDates[i-1].format('YYYY-MM-DD'),
                    count: 1,
                    direction: true,
                };
            } else if(weekserie[i] < weekserie[i-1]) {
                currentTrend = { 
                    t: i,
                    start: weekDates[i-1].format('YYYY-MM-DD'),
                    count: 1,
                    direction: false,
                };
            }
        }
      }
      if(currentTrend && currentTrend.count >= TREND_MINSAMPLE) {
        currentTrend.tend = weekserie.length - 1;
        trends.push(currentTrend)
      }

            // detect runs
      let currentRun = null;
      const RUN_MINSAMPLE = 1;
      let runs = [];
      for(let i = 0; i < weekserie.length; i++){
        console.log('at ' + i + ' ==> ' + (weekserie[i] > weekserie[i-1] ? 'up' : 'down'));
        if(currentRun) {
          if(currentRun.over) {
              // current val should be upper, else we break run
              if(weekserie[i] >= weekMedian[i]) {                  
                  currentRun.count++;                
              } else { // break run
                  if(currentRun.count >= RUN_MINSAMPLE) {
                    currentRun.tend = i-1;
                  runs.push(currentRun)
                }
                currentRun = null;
              }
          } else {
              // current val should be lower, else we break run
              if(weekserie[i] <= weekMedian[i]) {                  
                    currentRun.count++;                
              } else { // break trend
                if(currentRun.count >= RUN_MINSAMPLE) {
                  currentRun.tend = i-1;
                  trends.push(currentRun)
                }
                currentRun = null;
              }
          }        
        } 
        if(!currentRun)
         {
            if(weekserie[i] > weekMedian[i]) {
                currentRun = { 
                    tstart: i,                    
                    start: weekDates[i].format('YYYY-MM-DD'),
                    count: 1,
                    over: true,
                };
            } else if(weekserie[i] < weekMedian[i]) {
                currentRun = { 
                    t: i,
                    start: weekDates[i].format('YYYY-MM-DD'),
                    count: 1,
                    over: false,
                };
            }
        }
      }
      if(currentRun && currentRun.count >= RUN_MINSAMPLE) {
        currentRun.tend = weekserie.length - 1;
        runs.push(currentRun)
      }

      let minmaxrun = this.getRunMinMax(weekMedian.length);
      let runEval = this.$t('Impossible to evaluate runs');
      //debugger
      if(!minmaxrun && weekMedian.length < 10) runEval = this.$t('Not enough samples to evaluate runs');
      if(minmaxrun && minmaxrun.min <= runs.length && runs.length <= minmaxrun.max) runEval = this.$t('{runs} runs counted', { runs: runs.length });
      if(minmaxrun && minmaxrun.max <= runs.length) runEval = this.$t('Too much runs counted') + ' ' + runs.length;
      if(minmaxrun && minmaxrun.min >= runs.length) runEval = this.$t('Too few runs counted')+ ' ' + runs.length;
            
      
      this.changes = changes;
      this.runs = runs;
      this.runsEval = runEval;
      this.trends = trends;

      // now draw
      let xAxisActions = this.$store.state.fleet.cqs.map(c => { return {
              x: moment(c.date).format('YYYY-MM-DD'),
              borderColor: '#775DD0',
              label: {
                style: {
                  color: '#000',
                },
                text: c.title
              }
          }});
      let xAxisChanges = changes.map(c => { return {
                x: c.start,
                x2: c.end,
                fillColor: c.direction ? '#ffcccc' : '#B3F7CA',
                opacity: 0.4,
                label: {
                  borderColor: '#B3F7CA',
                  style: {
                    fontSize: '10px',
                    color: '#fff',
                    background: c.direction ? '#ffcccc' : '#B3F7CA',
                  },
                  offsetY: -10,
                  text: c.direction ? this.$t('Negative Change') : this.$t('Positive Change'),
                }
          } 
      });


      const options = {
          chart: {
            height: 550,
            type: 'line',
            id: 'areachart-2'
          },
          
          annotations: {
          xaxis: 
            [...xAxisActions, ...xAxisChanges]
          },
          
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          grid: {
            padding: {
              right: 30,
              left: 20
            }
          },
          title: {
            text: this.$t('Run Charts'),
            align: 'left'
          },
          labels: y,
          xaxis: {
            
            labels: {
              rotate: -45,
              rotateAlways: true,
              minHeight: 60,
              maxHeight: 200,
            }            
          },
          yaxis: {
           labels: {
             formatter: (v) => { return parseFloat(v).toFixed(2); }
           } 
          },
          legend: {
            position: 'top'
          }
        };

      
                


                            
      this.paqsOptions = options;      
      this.paqsSeries = series;
      this.dirtyFilter = false;
      
    },

    createCQS() {
      let cqs = { create: true}
      this.$store.commit('user/SHOW_CQS',cqs );      
    },
    editCQS(item) {      
      this.$store.commit('user/SHOW_CQS',item);      
    },
    deleteCQS(item) {      
      this.$store.commit('user/SHOW_CQSDELETOR',item);
    },
  },
  watch: {
    "$store.state.user.cqsEditorShow": function(val) {      
      if(!val) this.computeRunningChart();
    },
    "$store.state.user.cqsDeletorShow": function(user) {      
      if(!user) this.computeRunningChart();      
           
    }
  }
};
</script>


<style lang="scss" scoped>
.fitem { flex-grow: 1; }
.fcontainer {
  display: flex;
  flex-wrap: wrap;
}

</style>
