<template>
  
    <b-modal
      hide-footer
      v-model="showUserDeletor"
      @hidden="close"
      title="Delete Action">      
      <b-card >
        <h3>Confirmation before deletion</h3>
        <p class="text-justify">
            This operation is irreversible. 
        </p>
        <p>Please enter the date '{{$store.state.user.cqsDeletorData ? moment($store.state.user.cqsDeletorData.date).format('YYYY-MM-DD') : ''}}' to confirm the deletion</p>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
          <b-form @submit.stop.prevent="handleSubmit(apply)">
            <validation-provider
              name="Username"
              :rules="{ required: true, is:$store.state.user.cqsDeletorData ? moment($store.state.user.cqsDeletorData.date).format('YYYY-MM-DD'): 'mlkjmlkj' }"
              v-slot="validationContext"
            >
              <b-form-group
                label-cols-sm="3"
                label="Username:"
                label-align-sm="left"
                label-for="username"
              >
                <b-form-input
                  v-model="username"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

                        
                <b-button  class="float-left" @click="close">
                    {{ $t('Cancel')}}
                </b-button>
                <b-button  :loading="requesting" variant="danger" class="float-right" type="submit">
                    {{ $t('Delete')}}
                </b-button>
            

          </b-form>
        </validation-observer>
      </b-card>
    </b-modal>  
</template>

<script>
import { AuthService } from '@/services/auth.service'
import { SPService } from "@/services/sp.service";
import { API_URL } from '@/.env'
import moment from 'moment';

export default  {
  components: {
    
  },  
  data() {
    return {
      showUserDeletor: false     ,
      requesting: false, 
      username: null,
    }
  },
  watch : {
    '$store.state.user.cqsDeletorShow': function(val) {               
        this.showUserDeletor = val     
        this.requesting = false
    }
  },    
  methods: {
        moment,
        close() {           
            this.$store.commit('user/SHOW_CQSDELETOR',null);                        
        },
        getValidationState({ dirty, validated, valid = null }) {
          return dirty || validated ? valid : null;
        },
        async apply() {
          this.requesting = true
          let answer = await SPService.deleteCQS(API_URL.url, this.$store.state.user.currentUser.jwt, this.$store.state.user.cqsDeletorData.oid)
          this.$store.dispatch('fleet/loadCQS');
                
          this.requesting = false
          this.$store.commit('user/SHOW_CQSDELETOR',null);
        }
    },
  mounted() {      
     this.username = '';
      this.$store.commit('user/SHOW_CQSDELETOR',null);
  },
  name: "UserDeletionModal"
}
</script>

<style lang="scss" scoped>

</style>
