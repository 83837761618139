<template>
  
    <b-modal
      size="lg"
      hide-footer
      v-model="showVideoWatcher"
      :title="($store.state.fleet.watchingVideoAlert ? ($store.state.fleet.watchingVideoAlert.room  + ' / ' +  prettyDate($store.state.fleet.watchingVideoAlert.date)) : '' )"
      @hidden="close"      
      >
         
        <div class="wrapper" v-if="$store.state.fleet.watchingVideoAlert && $store.state.fleet.watchingVideoAlert.children && $store.state.fleet.watchingVideoAlert.children.length > 0">
          <b-tabs  pills  active  v-model="tabIndex">
              <b-tab :key="index" v-for="(a, index) in $store.state.fleet.watchingVideoAlert.children" :title="$t('Alert') +' ' + (index+1) + '/' + ($store.state.fleet.watchingVideoAlert.children.length)" style="form-control-rounded">
              <div class="row">
                <b-col cols="12">          
                  <video-player  v-if="showVideoWatcher" controls class="vjs-fill vjs-default-skin vjs-big-play-centered" 
                          ref="vid"
                          :options="playerOptions[index+1]"
                          :playsinline="true"
                          @ready="playerReadied"       
                          aspect-ratio="4:3"
                          data-setup='{ "controlBar": { "volumeMenuButton": false } }'
                          />
                </b-col>
                <b-col cols="12">
                  <button @click="download">Download video</button>         
                  <div class="d-flex">
                    <div class="p-2 align-self-end"><span>{{$t('Date') + ': ' + ($store.state.fleet.watchingVideoAlert ? prettyDate($store.state.fleet.watchingVideoAlert.date) : '')}}</span></div>              
                    <div class="ml-auto p-2"><b-button type=”button”
                        variant="light"                  
                        v-clipboard:copy="link"
                        v-clipboard:success="onCopy"     
                        v-clipboard:error="onError"             
                        ><font-awesome-icon style="margin-right: 1rem;" icon="link"/>{{$t('Copy link')}}</b-button></div>
                  </div>
                  <div class="d-flex">
                    <div class="p-2">
                        <span>{{$t('Room') + ': ' + ($store.state.fleet.watchingVideoAlert ? $store.state.fleet.watchingVideoAlert.room : '')}}</span><br/>
                    </div>
                  </div>
                  
                  
                  <div class="d-flex">
                    <div class="p-2">
                        <span>{{ ($store.state.fleet.watchingVideoAlert ? ($store.state.fleet.watchingVideoAlert.type === 4 ? $t('Bed Exit') : $t('Fall')) : '') + ' ' + $t('occured at') + ' ' + ($store.state.fleet.watchingVideoAlert ? prettyTime($store.state.fleet.watchingVideoAlert.date) : '')}}</span>
                    </div>
                  </div>

                </b-col>
                </div>
              </b-tab>
          </b-tabs>
        </div>

        <div v-else class="wrapper" >
          <div class="row">
          <b-col cols="12">          
            <video-player  v-if="showVideoWatcher" controls class="vjs-fill vjs-default-skin vjs-big-play-centered" 
                    ref="vid"
                    :options="playerOptions"
                    :playsinline="true"
                    @ready="playerReadied"       
                    aspect-ratio="4:3"
                    data-setup='{ "controlBar": { "volumeMenuButton": false } }'
                    />
          </b-col>
          <b-col cols="12">
            <button @click="download">Download video</button>         
            <div class="d-flex">
              <div class="p-2 align-self-end"><span>{{$t('Date') + ': ' + ($store.state.fleet.watchingVideoAlert ? prettyDate($store.state.fleet.watchingVideoAlert.date) : '')}}</span></div>              
              <div class="ml-auto p-2"><b-button type=”button”
                  variant="light"                  
                  v-clipboard:copy="link"
                  v-clipboard:success="onCopy"     
                  v-clipboard:error="onError"             
                  ><font-awesome-icon style="margin-right: 1rem;" icon="link"/>{{$t('Copy link')}}</b-button></div>
            </div>
            <div class="d-flex">
               <div class="p-2">
                  <span>{{$t('Room') + ': ' + ($store.state.fleet.watchingVideoAlert ? $store.state.fleet.watchingVideoAlert.room : '')}}</span><br/>
               </div>
            </div>
            
            
            <div class="d-flex">
               <div class="p-2">
                  <span>{{ ($store.state.fleet.watchingVideoAlert ? ($store.state.fleet.watchingVideoAlert.type === 4 ? $t('Bed Exit') : $t('Fall')) : '') + ' ' + $t('occured at') + ' ' + ($store.state.fleet.watchingVideoAlert ? prettyTime($store.state.fleet.watchingVideoAlert.date) : '')}}</span>
               </div>
            </div>

            
            
          </b-col>
          </div>
        </div>
      
    </b-modal>  
</template>

<script>

import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
import { API_URL } from '../../../.env'
import moment from 'moment';
import { IMGService } from '@/services/img.service'
import { SPService } from '@/services/sp.service.js'

export default  {
  components: {    
    videoPlayer
  },  
  mounted() {  
    
  },
  data() {
    return {
      showVideoWatcher: false
    }
  },
  watch : {
    '$store.state.fleet.watchingVideo': function(val) {   
      console.log('watchingVideo changed')
      if(val === false)
        this.showVideoWatcher = false
     else 
     {       
        this.showVideoWatcher = val != null || val === true
        SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'report_watchvideo',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
     }
      }
  },
  computed: {
    link() {
      const link = `${window.location.origin}/capture/${this.$store.state.fleet.watchingVideoAlert ? this.$store.state.fleet.watchingVideoAlert.video : ''}`      
      return link
    },
    show() {
       return this.$store.state.fleet.watchingVideo
    },
    hasPreview() {
      return this.$store.state.fleet.watchingImgLoaded != null
    },
    livePreview() {
      return 'data:image/png;base64,' + this.$store.state.fleet.watchingImgLoaded
    },
    action() {
      return this.$store.state.fleet.watchingImgLoaded == null ? '' : 'Live from ' + this.$store.state.fleet.watchingLabel
    },
    source() {
      return API_URL.url +'/report/video/' + this.$store.state.fleet.watchingVideoOid + '?o=' + this.$store.state.user.currentUser.oid
    },
    playerOptions() {
      console.log('changing video options')
      let baseOptions = 
      {
          // videojs options
          muted: true,
          autoplay: true,
          controls: true,
          fluid: true,
          language: 'en',
          controlBar: {
            timeDivider: false,
            durationDisplay: false,
            volumePanel: false,
          },
          playbackRates: [0.3, 0.5, 1, 2.0, 3.0, 5.0],
          sources: [{
            withCredentials: false,
            type: "video/mp4",
            src: API_URL.url +'/report/video/' + this.$store.state.fleet.watchingVideoOid + '?o=' + this.$store.state.user.currentUser.oid
          }],
          poster: IMGService.isalogo,
          
          responsive: true,
          preload: 'metadata',
        };
        if(!this.$store.state.fleet.watchingVideoAlert.children || this.$store.state.fleet.watchingVideoAlert.children.length === 0)
          return baseOptions;
        else 
          {
              let options = [];
              options.push(baseOptions);
              for(let v of this.$store.state.fleet.watchingVideoAlert.children) {
                options.push({              
                  muted: true,
                  autoplay: true,
                  controls: true,
                  fluid: true,
                  language: 'en',
                  controlBar: {
                    timeDivider: false,
                    durationDisplay: false,
                    volumePanel: false,
                  },
                  playbackRates: [0.3, 0.5, 1, 2.0, 3.0, 5.0],
                  sources: [{
                    withCredentials: false,
                    type: "video/mp4",
                    src: API_URL.url +'/report/video/' + v.videoUrl + '?o=' + this.$store.state.user.currentUser.oid
                  }],
                  poster: IMGService.isalogo,
                  
                  responsive: true,
                  preload: 'metadata',
                });
              }
              return options;
        }
    }

  },
  methods: {       
    close()  {      
      this.$store.dispatch('fleet/watchVideo',null)
    },    
    onCopy(e) {
      this.$bvToast.toast(this.$t('The link to this video has been copied, you can now share it.\nOnly authenticated people with appropriates rights will be able to access it.'), { title: this.$t('Video'), solid: true,  variant: 'success',  duration: 3000 });
    },
    onError(e) {
      this.$bvToast.toast(this.$t('Your browser does not support this feature.'), { title: this.$t('Video'), solid: true,  variant: 'warning',  duration: 3000 });
    },  
    playerReadied(player) {
    },
    download() {
      if(!this.$store.state.fleet.watchingVideoAlert.children || this.$store.state.fleet.watchingVideoAlert.children.length === 0)
      {
        const url = API_URL.url +'/report/video/' + this.$store.state.fleet.watchingVideoOid + '?o=' + this.$store.state.user.currentUser.oid; 
        const link = document.createElement('a')
        link.href = url
        link.download = this.$store.state.fleet.watchingVideoOid + '.mp4'
        link.click()
      }
      else
      { 
        let options = [];
        for(let v of this.$store.state.fleet.watchingVideoAlert.children) {
          options.push({
              video_url: v.videoUrl
          });
        }
        const url = API_URL.url +'/report/video/' + options[this.tabIndex].video_url + '?o=' + this.$store.state.user.currentUser.oid; 
        const link = document.createElement('a')
        link.href = url
        link.download = options[this.tabIndex].video_url  + '.mp4'
        link.click()
      }
    },
    prettyDate(d) {
      return moment(d).format('LL');
    },
    prettyTime(d) {
      return moment(d).format('HH:mm Z');
    }
  },
  name: "ISARoomWatcherModal"
}
</script>

<style lang="scss" scoped>
.isapreviewimg {
  width: 100%
}
.video-js .vjs-volume-menu-button {
  display:none;
}
.video-js.vjs-fullscreen .vjs-volume-menu-button {
  display:block;
}

.video-js .vjs-mute-control, .video-js .vjs-volume-menu-button {
  display: none;
}
</style>
