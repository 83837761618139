<template>
    <div>
    
    </div>
</template>

<script>
import moment from 'moment';

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "ISAPAdmissions"
  },
  props: {
    
  },
  components: {
    
  },
  data() {
    return {    
      moment,  
      isLoading: false,

      fields: [
        { key: "date", label: this.$t('Date'), sortable: true },
        { key: "title", label: this.$t('Title'), sortable: true },
        { key: "scope", label: this.$t('Scope'),sortable: false },        
      ],
      
      scopeChoice: 0,
      timeRangeOptions: [               
        { value: 5, text: this.$t("Last month") },
        { value: 6, text: this.$t("Last 3 months") },
        { value: 7, text: this.$t("Last 6 months") },
        { value: 8, text: this.$t("Custom") },
      ],
      scopeOptions: [
          { value: 0, text: this.$t('All Care Units')},
          { value: 1, text: this.$t('Care Units')},
          { value: 2, text: this.$t('Room')}
          ],
      zzscope: 0,

      mAllRoomSelected: false,
      mRoomFilter: [],
      mRoomsetFilter: [],
      
      paqsOptions: {},
      paqsSeries: {},


      showChanges: true,
      showTrends: true,
      showRuns: true,
      showOutlier: true,
      
      dirtyFilter: false,
      room2roomset: {},

      changes: [],
      runs: [],
      runsEval: '',
      trends: [],
      outliers: [],

    };
  },
  computed: {
    
  },
  mounted() {       
      this.$store.dispatch('fleet/loadPAdmissions')        
  },
  methods: {

    getRunMinMax(count) {
        if(count < 10) return null;
        switch(count) {
            case 10: return { min: 3  , max: 8 }
            case 11: return { min: 3  , max: 9 }
            case 12: return { min: 3  , max: 10 }
            case 13: return { min: 4  , max: 10 }
            case 14: return { min: 4  , max: 11 }
            case 15: return { min: 4  , max: 12 }
            case 16: return { min: 5  , max: 12 }
            case 17: return { min: 5  , max: 13 }
            case 18: return { min: 6  , max: 13 }
            case 19: return { min: 6  , max: 14 }
            case 20: return { min: 6  , max: 15 }
            case 21: return { min: 7  , max: 15 }
            case 22: return { min: 7  , max: 16 }
            case 23: return { min: 8  , max: 16 }
            case 24: return { min: 8  , max: 17 }
            case 25: return { min: 9  , max: 17 }
            case 26: return { min: 9  , max: 18 }
            case 27: return { min: 9  , max: 19 }
            case 28: return { min: 10 , max:  19 }
            case 29: return { min: 10 , max:  20 }
            case 30: return { min: 11 , max:  20 }
            case 31: return { min: 11 , max:  21 }
            case 32: return { min: 11 , max:  22 }
            case 33: return { min: 11 , max:  22 }
            case 34: return { min: 12 , max:  23 }
            case 35: return { min: 13 , max:  23 }
            case 36: return { min: 13 , max:  24 }
            case 37: return { min: 13 , max:  25 }
            case 38: return { min: 14 , max:  25 }
            case 39: return { min: 14 , max:  26 }
            case 40: return { min: 15 , max:  26 }
            case 41: return { min: 16 , max:  26 }
            case 42: return { min: 16 , max:  27 }
            case 43: return { min: 17 , max:  27 }
            case 44: return { min: 17 , max:  28 }
            case 45: return { min: 17 , max:  29 }
            case 46: return { min: 17 , max:  30 }
            case 47: return { min: 18 , max:  30 }
            case 48: return { min: 18 , max:  31 }
            case 49: return { min: 19 , max:  31 }
            case 50: return { min: 19 , max:  32 }
        }
        return null;
    },

    computeRunningChart() {
      if(!this.$store.state.fleet.filteredAlertList || this.$store.state.fleet.filteredAlertList.length === 0) return;
      
      const perWeek = {};
        let minW = moment();
        let maxW = moment().add(-2,'years');
        let alerts = [];
        
        switch (this.zzscope) {
          case 0: { // all care units / organization
            alerts = this.$store.state.fleet.filteredAlertList.filter(a => a.organizationLabel === this.$store.state.fleet.currentOrganization);
            break;
          }
          case 1: { // specific care unit
              let rsoid = this.mRoomsetFilter.filter(p => p.value).map( p => p.oid);
              alerts = this.$store.state.fleet.filteredAlertList.filter(a => rsoid.includes(this.room2roomset[a.roomOID]));
              break;
          }          
          case 2: { // specific rooms
               let roid = this.mRoomFilter.filter(p => p.value).map( p => p.oid);              
              alerts = this.$store.state.fleet.filteredAlertList.filter(a => roid.includes(a.roomOID));
              break;
          }
      }

        for (const a of alerts) {
            const m = moment(a.date).year() * 100 + moment(a.date).isoWeek();
            if (moment(a.date) > maxW) maxW = moment(a.date);
            if (moment(a.date) < minW) minW = moment(a.date);


            if (!perWeek[m]) perWeek[m] = { be: 0, f: 0 };
            if (a.type === 5) perWeek[m].f++;
            if (a.type === 4) perWeek[m].be++;            
        }

        
        let y = [];      
        const series = [
          { name: this.$t('Falls'), data: []},
          { name: this.$t('Median'), data: []},
        ]
        
        let ld = minW.clone(); 
        let yd = minW.clone(); 
        while(yd < maxW) 
        {
            yd = yd.clone().add(1, 'day');                                                       
            y.push(yd.format('YYYY-MM-DD'));
        }
        let weekserie = [];
        let weekMedian = [];
        let weekDates = [];
        while(ld < maxW) 
        {            
            let mld = (moment(ld).year() * 100) + ld.isoWeek();
            let nld = (moment(ld).year() * 100) + (ld).isoWeek() + 1;
            console.log(ld.format('YYYY-MM-DD') + ' --> ' + mld + ' week = '  + ld.isoWeek())            
           // y.push(ld.format('YYYY-MM-DD'));

          
            let falls = perWeek[mld]  ? perWeek[mld].f : null;
            let nextFall = perWeek[nld]  ? perWeek[nld].f : null;
            weekserie.push(falls || 0);
            weekDates.push(ld);
            for(let i = 0; i < 7; i++)                  
              series[0].data.push(falls + i * (nextFall - falls) / 7 );            
            ld = ld.clone().add(1, 'week');
        }
        let medianS = weekserie.concat().sort((a,b) => a - b);
        let median = medianS[Math.floor(medianS.length/2)];
        for(let i of series[0].data) {
          series[1].data.push(median);
        }
        for(let i of weekserie) {
          weekMedian.push(median);
        }

      // PAQS detection part
      // detect changes
      let currentChange = null;

      let changeSerie = [];      
      for(let i = 0; i < weekserie.length; i++){
        if(weekserie[i] !== weekMedian[i])
            changeSerie.push({ t: i, v: weekserie[i], m: weekMedian[i]});
      }

      let changes = [];
      const CHANGE_MINSAMPLE = 4;
      
      for(let tt = 1; tt < changeSerie.length; tt++)
      {
        let pv = changeSerie[tt-1];
        let cv = changeSerie[tt];

        
        if(cv.v > cv.m && pv.v < pv.m || cv.v < cv.m && pv.v > pv.m) // local change here
        {
          if(!currentChange) // we have a new change
          {
              currentChange = {
                t: cv.t,
                start: weekDates[cv.t-1].format('YYYY-MM-DD'),
                count: 1,
                direction: cv.v > cv.m // true = up, false = down
              }
          } else { // we are breaking a change
              if(currentChange.count >= CHANGE_MINSAMPLE) { // that was a change!
                currentChange.end = weekDates[cv.t-1].format('YYYY-MM-DD')
                changes.push(changes)
              }
              currentChange = null;
          }
        } else { // stable over or under
          if(currentChange) {
            currentChange.count++;
          }
        }
      }
      if(currentChange && currentChange.count >= CHANGE_MINSAMPLE) { // that was a change!
          currentChange.end = weekDates[weekDates.length-1].format('YYYY-MM-DD')
          changes.push(currentChange)
      }
      


      // detect trends
      let currentTrend = null;
      const TREND_MINSAMPLE = 5;
      let trends = [];
      for(let i = 1; i < weekserie.length; i++){
        console.log('at ' + i + ' ==> ' + (weekserie[i] > weekserie[i-1] ? 'up' : 'down'));
        if(currentTrend) {
          if(currentTrend.direction) {
              // current val should be upper, else we break trend
              if(weekserie[i] >= weekserie[i-1]) {
                  if(weekserie[i] > weekserie[i-1])
                      currentTrend.count++;                
              } else { // break trend
                if(currentTrend.count >= TREND_MINSAMPLE) {
                  currentTrend.tend = i-1;
                  trends.push(currentTrend)
                }
                currentTrend = null;
              }
          } else {
              // current val should be lower, else we break trend
              if(weekserie[i] <= weekserie[i-1]) {
                  if(weekserie[i] < weekserie[i-1])
                    currentTrend.count++;                
              } else { // break trend
                if(currentTrend.count >= TREND_MINSAMPLE) {
                  currentTrend.tend = i-1;
                  trends.push(currentTrend)
                }
                currentTrend = null;
              }
          }        
        } 
        if(!currentTrend)
         {
            if(weekserie[i] > weekserie[i-1]) {
                currentTrend = { 
                    tstart: i,                    
                    start: weekDates[i-1].format('YYYY-MM-DD'),
                    count: 1,
                    direction: true,
                };
            } else if(weekserie[i] < weekserie[i-1]) {
                currentTrend = { 
                    t: i,
                    start: weekDates[i-1].format('YYYY-MM-DD'),
                    count: 1,
                    direction: false,
                };
            }
        }
      }
      if(currentTrend && currentTrend.count >= TREND_MINSAMPLE) {
        currentTrend.tend = weekserie.length - 1;
        trends.push(currentTrend)
      }

            // detect runs
      let currentRun = null;
      const RUN_MINSAMPLE = 1;
      let runs = [];
      for(let i = 0; i < weekserie.length; i++){
        console.log('at ' + i + ' ==> ' + (weekserie[i] > weekserie[i-1] ? 'up' : 'down'));
        if(currentRun) {
          if(currentRun.over) {
              // current val should be upper, else we break run
              if(weekserie[i] >= weekMedian[i]) {                  
                  currentRun.count++;                
              } else { // break run
                  if(currentRun.count >= RUN_MINSAMPLE) {
                    currentRun.tend = i-1;
                  runs.push(currentRun)
                }
                currentRun = null;
              }
          } else {
              // current val should be lower, else we break run
              if(weekserie[i] <= weekMedian[i]) {                  
                    currentRun.count++;                
              } else { // break trend
                if(currentRun.count >= RUN_MINSAMPLE) {
                  currentRun.tend = i-1;
                  trends.push(currentRun)
                }
                currentRun = null;
              }
          }        
        } 
        if(!currentRun)
         {
            if(weekserie[i] > weekMedian[i]) {
                currentRun = { 
                    tstart: i,                    
                    start: weekDates[i].format('YYYY-MM-DD'),
                    count: 1,
                    over: true,
                };
            } else if(weekserie[i] < weekMedian[i]) {
                currentRun = { 
                    t: i,
                    start: weekDates[i].format('YYYY-MM-DD'),
                    count: 1,
                    over: false,
                };
            }
        }
      }
      if(currentRun && currentRun.count >= RUN_MINSAMPLE) {
        currentRun.tend = weekserie.length - 1;
        runs.push(currentRun)
      }

      let minmaxrun = this.getRunMinMax(weekMedian.length);
      let runEval = this.$t('Impossible to evaluate runs');
      //debugger
      if(!minmaxrun && weekMedian.length < 10) runEval = this.$t('Not enough samples to evaluate runs');
      if(minmaxrun && minmaxrun.min <= runs.length && runs.length <= minmaxrun.max) runEval = this.$t('{runs} runs counted', { runs: runs.length });
      if(minmaxrun && minmaxrun.max <= runs.length) runEval = this.$t('Too much runs counted') + ' ' + runs.length;
      if(minmaxrun && minmaxrun.min >= runs.length) runEval = this.$t('Too few runs counted')+ ' ' + runs.length;
            
      
      this.changes = changes;
      this.runs = runs;
      this.runsEval = runEval;
      this.trends = trends;

      // now draw
      let xAxisActions = this.$store.state.fleet.cqs.map(c => { return {
              x: moment(c.date).format('YYYY-MM-DD'),
              borderColor: '#775DD0',
              label: {
                style: {
                  color: '#000',
                },
                text: c.title
              }
          }});
      let xAxisChanges = changes.map(c => { return {
                x: c.start,
                x2: c.end,
                fillColor: c.direction ? '#ffcccc' : '#B3F7CA',
                opacity: 0.4,
                label: {
                  borderColor: '#B3F7CA',
                  style: {
                    fontSize: '10px',
                    color: '#fff',
                    background: c.direction ? '#ffcccc' : '#B3F7CA',
                  },
                  offsetY: -10,
                  text: c.direction ? this.$t('Negative Change') : this.$t('Positive Change'),
                }
          } 
      });


      const options = {
          chart: {
            height: 550,
            type: 'line',
            id: 'areachart-2'
          },
          
          annotations: {
          xaxis: 
            [...xAxisActions, ...xAxisChanges]
          },
          
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          grid: {
            padding: {
              right: 30,
              left: 20
            }
          },
          title: {
            text: this.$t('Run Charts'),
            align: 'left'
          },
          labels: y,
          xaxis: {
            
            labels: {
              rotate: -45,
              rotateAlways: true,
              minHeight: 60,
              maxHeight: 200,
            }            
          },
          yaxis: {
           labels: {
             formatter: (v) => { return v; }
           } 
          },
          legend: {
            position: 'top'
          }
        };

      
                


                            
      this.paqsOptions = options;      
      this.paqsSeries = series;
      this.dirtyFilter = false;
      
    },

    createPAdmission() {
      let cqs = { create: true}
      this.$store.commit('user/SHOW_PADMISSION',cqs );      
    },
    editAdmission(item) {      
      this.$store.commit('user/SHOW_PADMISSION',item);      
    },
    deleteAdmission(item) {      
      this.$store.commit('user/SHOW_PADMISSIONDELETEOR',item);
    },
  },
  watch: {
    "$store.state.user.pAdmissionEditorShow": function(val) {      
      
    },
    "$store.state.user.pAdmissionDeletorShow": function(user) {      
                 
    }
  }
};
</script>


<style lang="scss" scoped>
.fitem { flex-grow: 1; }
.fcontainer {
  display: flex;
  flex-wrap: wrap;
}

</style>