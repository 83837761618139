<template>
    <b-table
      hover
      sm      
      striped      
      responsive     
       stacked="md"  
      light
      class="text-center"      
      :items="$store.state.fleet.filteredAlertList"      
      :busy="$store.state.fleet.updatingStat"
      :fields="fields"
      style="margin: 10px"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{ $t('Loading...') }}</strong>
        </div>
      </template>

      <template v-slot:head(detection)="data">
        <span>{{ data.label }}</span>
        <font-awesome-icon style="margin-left: 1rem;" icon="question-circle" @click.stop="$store.commit('user/NEEDS_HELP','ISAAlertListDetectionHelp')"/>
      </template>
      <template v-slot:head(video)="data">
        <span>{{ data.label }}</span>
        <font-awesome-icon style="margin-left: 1rem;" icon="question-circle" @click.stop="$store.commit('user/NEEDS_HELP','ISAAlertListVideoHelp')"/>
      </template>
      <template v-slot:head(notifications)="data">
        <span>{{ data.label }}</span>
        <font-awesome-icon style="margin-left: 1rem;" icon="question-circle" @click.stop="$store.commit('user/NEEDS_HELP','ISAAlertListNotificationHelp')"/>
      </template>

      <template v-slot:cell(video)="row">
        <b-button
          v-if="row.item.hasVideo && row.item.videoAvailable"
          size="sm"
          class="mr-1 btnedit"
          @click="watchVideo(row.item)"
        >        
          <font-awesome-icon icon="photo-video" />
          <b-badge v-if="row.item.children" variant="primary" style="margin-left:1rem; color: black">{{row.item.children.length}}</b-badge>
        </b-button>
        <i
          v-if="row.item.hasVideo && !row.item.videoAvailable && moment(row.item.date).add('hours',1) < moment()"
          style="color: orange"
          ><font-awesome-icon
            icon="compress-alt"
            variant="warning"
            style="margin-right: 0.5rem"
          ></font-awesome-icon
          >{{ $t('Video is preparing') }}</i
        >
        <i v-if="!row.item.hasVideo" style="color: grey"
          ><font-awesome-icon
            icon="exclamation-circle"
            variant="warning"
            style="margin-right: 0.5rem"
          ></font-awesome-icon
          >{{ $t('Not Available') }}</i
        >
      </template>
      <template v-slot:cell(type)="row">
        {{ row.item.type === 4 ? $t("Bed Exit") : $t("Fall") }}
      </template>
      <template v-slot:cell(date)="row">
        {{ moment(row.item.date).format("YYYY-MM-DD HH:mm:ss") }}
      </template>
      <template v-slot:cell(notifications)="row">
        <b-button
          size="sm"
          @click="watchNotificationHistory(row.item)"
          class="btndelete"
          v-if="row.item.detection !== 'ND'"
        >
          <font-awesome-icon icon="phone" />
        </b-button>
        <b-button
          size="sm"
          class="btndelete close"
          type="button"
          aria-label="Close"
          v-if="row.item.detection === 'ND'"
        >
          
        </b-button>
      </template>
      <template v-slot:cell(detection)="row">
        <!-- Verified tick vert -->
        <!-- trigged = phone -->
        <i
          v-if="row.item.validation !== 'UN' && row.item.detection === 'TD' && !row.item.isTest"
          style="color: green"
          ><font-awesome-icon
            icon="check"
            variant="success"
            style="margin-right: 0.5rem"
          ></font-awesome-icon
          >{{ $t('Verified') }}</i
        >
        <i v-if="row.item.validation === 'UN' && !row.item.isTest" style="color: lightgreen"
          ><font-awesome-icon
            icon="user-clock"
            variant="warning"
            style="margin-right: 0.5rem"
          ></font-awesome-icon
          >{{ $t('Not yet verified') }}</i
        >
        <!-- FA = cross -->
        <i v-if="row.item.detection === 'FA' && !row.item.isTest" style="color: gray">
          {{ $t('False Alert') }}</i
        >
        <i v-if="row.item.detection === 'ND' && !row.item.isTest" style="color: gray">        
          {{ $t('Delayed Alert') }}</i >
        <i v-if="row.item.isTest" style="color: gold"
          ><font-awesome-icon
            icon="exclamation-circle"
            variant="warning"
            style="margin-right: 0.5rem"
          ></font-awesome-icon
          >{{ $t('Test Alert') }}</i
        >
      </template>

      
    </b-table>
</template>

<script>
import moment from 'moment';
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Alert List"
  },
  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false,      
    },
  },
  data() {
    return {    
      moment,  
      isBusy: false,
      fields: [
        { key: "date", label: this.$t("Date"),sortable: true },
        { key: "roomset", label: this.$t("Care Unit"), sortable: true },
        { key: "room", label: this.$t("Room"),sortable: true },
        { key: "type", label: this.$t("Type"),sortable: true },
        { key: "detection", label: this.$t("Status"), sortable: true },
        { key: "video", label: this.$t("Video"),sortable: true },
        { key: "notifications", label: this.$t("Phone Calls"),sortable: true },
      ],
      
    };
  },
  mounted() {    
  },
  methods: {    
    watchVideo(f){
        this.$store.dispatch('fleet/watchVideo',f)      
    },
    watchNotificationHistory(f){
        this.$store.dispatch('fleet/watchNotificationhistory',f)
    },
  }
};
</script>


<style lang="scss" scoped>


</style>