<template>
    <b-row style="smargin-top: 0px; margin-bottom: 0px">
        <b-col lg="6" xl="6" md="12">
            <b-card :title="$t('Event History')" class="mb-30">                
                <div id="events-chart" style="min-height: 250px; max-height: 250px" v-if="graph_events_mode === 'days'">
                    <apexchart type="bar"
                               height="260"
                               :options="graph_events_days_options"
                               :series="graph_events_days_timeline" />
                </div>
                <div id="events-chart" style="min-height: 250px; max-height: 250px" v-if="graph_events_mode === 'weeks'">
                    <apexchart v-if="graph_events_mode === 'weeks'"
                               type="bar"
                               height="260"
                               :options="graph_events_weeks_options"
                               :series="graph_events_weeks_timeline" />
                </div>
                <div id="events-chart" style="min-height: 250px; max-height: 250px" v-if="graph_events_mode === 'months'">
                    <apexchart v-if="graph_events_mode === 'months'"
                               type="bar"
                               height="260"
                               :options="graph_events_months_options"
                               :series="graph_events_months_timeline" />
                </div>
            </b-card>
        </b-col>
        <b-col lg="6" xl="6" md="12">
            <b-card :title="$t('Hour of day')" class="mb-30">
                <div id="basicArea-chart" style="min-height: 250px; max-height: 250px"  v-if="!$store.state.fleet.updatingStats">
                    <apexchart
                               type="bar"
                               height="260"
                               :options="graph_hist_options"
                               :series="graph_hist_timeline" />
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>

import moment from 'moment';
import { duration } from 'moment';


    export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: "Insights"
        },
        props: {
            modal: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                quantity: -1,
            }
        },
        computed: {
            evtext() { return  this.$t('Events')},
            graph_events_mode() {
                if (!this.$store.state.fleet.filteredAlertList || !this.$store.state.fleet.filteredAlertList.length === 0 || this.$store.state.fleet.updatingStats) return 'none';
                let durationHours = moment(this.$store.state.fleet.filter.dateFilter.end).diff(moment(this.$store.state.fleet.filter.dateFilter.start), 'hours');
                let month = Math.floor(durationHours / (24 * 30));
                let weeks = Math.floor(durationHours / (24 * 7));
                let days = Math.floor(durationHours / (24));
                let mode = 'days';
                this.quantity = days;
                if (month > 2) {
                    this.quantity = month;
                    mode = 'months';
                }
                else if (weeks > 2) {
                    mode = 'weeks';
                    this.quantity = weeks;
                }                    
                return mode;
            },

            graph_events_months_options() {
                let cat = [];
                if ( moment(this.$store.state.fleet.filter.dateFilter.end).diff(moment(this.$store.state.fleet.filter.dateFilter.start), 'months', true) > 3 )
                {
                    cat = [moment().add(-6, 'month').format('MM/YY'), moment().add(-5, 'month').format('MM/YY'), moment().add(-4, 'month').format('MM/YY'), moment().add(-3, 'month').format('MM/YY'), moment().add(-2, 'month').format('MM/YY'), moment().add(-1, 'month').format('MM/YY'), moment().format('MM/YY')];
                } 
                else 
                {
                    cat = [moment().add(-3, 'month').format('MM/YY'),moment().add(-2, 'month').format('MM/YY'), moment().add(-1, 'month').format('MM/YY'), moment().format('MM/YY')];
                }
                const options = {
                    chart: {
                        defaultLocale: this.$i18n.locale,
                        locales: this.apexLocales,
                        shadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 1
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },

                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: cat
                    },
                    yaxis: {
                        title: {
                            text: this.evtext
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    colors: ['#ED3434', '#4D3397', '#7d6cbb',],
                };
                return options;
            },
            graph_events_weeks_options() {

                let durationWeeks = moment(this.$store.state.fleet.filter.dateFilter.end).diff(moment(this.$store.state.fleet.filter.dateFilter.start), 'weeks')+1;
                let sw = moment(this.$store.state.fleet.filter.dateFilter.start).isoWeek();
                let y = [];
                let ld = moment().day("Monday").year(moment(this.$store.state.fleet.filter.dateFilter.start).year()).week(sw);
                let dm = moment().day("Sunday").year(moment(this.$store.state.fleet.filter.dateFilter.start).year()).week(sw);

                for (let w = sw; w <= sw + durationWeeks; w++) {
                    ld = moment().day("Monday").year(ld.year()).week(w);
                    if(ld < moment(this.$store.state.fleet.filter.dateFilter.start))
                    {
                        ld = moment(this.$store.state.fleet.filter.dateFilter.start);
                    } 
                    dm =  moment().day("Monday").year(ld.year()).week(w).add('days', 6);
                    if (dm > moment(this.$store.state.fleet.filter.dateFilter.end))
                    {
                        dm = new moment(this.$store.state.fleet.filter.dateFilter.end);
                    }
                    y.push(ld.format('DD/MM') + '-' + dm.format('DD/MM'));

                }
                const options = {
                    chart: {
                        defaultLocale: this.$i18n.locale,
                        locales: this.apexLocales,
                        shadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 1
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },

                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: y,
                    },
                    yaxis: {
                        title: {
                            text: this.evtext
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val 
                            }
                        }
                    },
                    colors: ['#ED3434', '#4D3397', '#7d6cbb',],
                };                
                return options;
            },
            
            graph_events_months_timeline() {
                if(this.$store.state.fleet.updatingStats) return [];
                const perMonth = {};
                for (const a of this.$store.state.fleet.filteredAlertList) {
                    const m = moment(a.date).format('YY/MM');
                    if (!perMonth[m]) perMonth[m] = { be: 0, f: 0 };
                    if (a.type === 5) perMonth[m].f++;
                    if (a.type === 4) perMonth[m].be++;
                }

                const sm = moment(this.$store.state.fleet.filter.dateFilter.start);
                const em = moment(this.$store.state.fleet.filter.dateFilter.end);

                const monthsdiff = moment(em, 'YY/MM').diff(moment(sm, 'YY/MM'), 'months')
                for (let i = 0; i<=monthsdiff; i++) {
                    const m = sm.clone().add(i,'months').format('YY/MM');
                    if (!perMonth[m]) perMonth[m] = { be: 0, f: 0 };
                }
                const series = [
                    { name: this.$t('Fall Events'), data: [] },
                    { name: this.$t('Bed Exit Events'), data: [] },
                ];
                
                Object.keys(perMonth).sort().forEach(function(v, i) {
                    let f = 0;
                    let be = 0;
                    if (perMonth[v]) {
                        f = perMonth[v].f;
                        be = perMonth[v].be;
                    }
                    series[0].data.push(f);
                    series[1].data.push(be);
                });
                return series; 
            },

            graph_events_weeks_timeline() {
                if(this.$store.state.fleet.updatingStats) return [];
                const perWeek = {};
                let minW = 9999;
                let maxW = -1;
                for (const a of this.$store.state.fleet.filteredAlertList) {
                    const m = moment(a.date).isoWeek();
                    if (m > maxW) maxW = m;
                    if (m < minW) minW = m;

                    if (!perWeek[m]) perWeek[m] = { be: 0, f: 0 };
                    if (a.type === 5) perWeek[m].f++;
                    if (a.type === 4) perWeek[m].be++;
                }
                const sw = moment(this.$store.state.fleet.filter.dateFilter.start).isoWeek();
                if (!perWeek[sw]) perWeek[sw] = { be: 0, f: 0}
                if ( sw > maxW) maxW = sw;
                if ( sw < minW) minW = sw;
                const ew = moment(this.$store.state.fleet.filter.dateFilter.end).isoWeek();
                if (!perWeek[ew]) perWeek[ew] = { be: 0, f: 0}
                if ( ew > maxW) maxW = ew;
                if ( ew < minW) minW = ew;

                const weeks = [];
                for (let w = minW; w <= maxW; w++) weeks.push(w);
                const series = [
                    { name: this.$t('Fall Events'), data: [] },
                    { name: this.$t('Bed Exit Events'), data: [] },
                ];
                for (const m of weeks) {
                    let f = 0;
                    let be = 0;
                    if (perWeek[m]) {
                        f = perWeek[m].f;
                        be = perWeek[m].be;
                    }
                    series[0].data.push(f);
                    series[1].data.push(be);
                }
                return series;
            },

            graph_events_days_options() {
                let durationDays = moment(this.$store.state.fleet.filter.dateFilter.end).diff(moment(this.$store.state.fleet.filter.dateFilter.start), 'days');
                if (moment(this.$store.state.fleet.filter.dateFilter.end).date() == moment.utc().date())
                {
                    durationDays = durationDays + 1; 
                };
                let y = [];                
                for (let w = 0; w < durationDays; w++) {
                    let ld = moment(this.$store.state.fleet.filter.dateFilter.start).add(w, 'days');
                    y.push(ld.format('DD/MM'));
                }
                const options = {
                    chart: {
                        defaultLocale: this.$i18n.locale,
                        locales: this.apexLocales,
                        shadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 1
                        },
                        toolbar: {
                            show: true
                        },
                        
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },

                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: y,
                    },
                    yaxis: {
                        title: {
                            text: this.evtext
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    colors: ['#ED3434', '#4D3397', '#7d6cbb',],
                };                
                return options;
            },
            graph_events_days_timeline() {
                if(this.$store.state.fleet.updatingStats) return [];
                const perDay = {};
                
                for (const a of this.$store.state.fleet.filteredAlertList) {
                    const m = moment(a.date).format('DD/MM');
                    if (!perDay[m]) perDay[m] = { be: 0, f: 0 };
                    if (a.type === 5) perDay[m].f++;
                    if (a.type === 4) perDay[m].be++;
                }
                
                const series = [
                   { name: this.$t('Fall Events'), data: [] },
                    { name: this.$t('Bed Exit Events'), data: [] },
                ];

                let durationDays = moment(this.$store.state.fleet.filter.dateFilter.end).diff(moment(this.$store.state.fleet.filter.dateFilter.start), 'days')+1;                                    
                for (let w = 0; w < durationDays; w++) {
                    let m = moment(this.$store.state.fleet.filter.dateFilter.start).add(w, 'days').format('DD/MM');
                    let f = 0;
                    let be = 0;
                    if (perDay[m]) {
                         f = perDay[m].f;
                        be = perDay[m].be;
                    }
                    series[0].data.push(f);
                    series[1].data.push(be);
                }
                return series;
                
            },

            graph_hist_options() {
                const options = {
                    chart: {
                        defaultLocale: this.$i18n.locale,
                        locales: this.apexLocales,
                        height: 350,
                        type: "bar",    
                        toolbar: {
                            show: false
                        },                    
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 16,
                            horizontal: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                        style: {
                            colors: ['#333']
                        },
                    },
                    fill: {
                        /*
                        type: "gradient",
                        gradient: {
                            shade: "light",
                            type: "vertical",
                            shadeIntensity: 0.25,
                            gradientToColors: undefined,
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [50, 0, 100, 100],
                        },*/
                    },
                    labels: [
                        /*"0h->1h",
                        "1h->2h",
                        "2h->3h",
                        "3h->4h",
                        "4h->5h",
                        "5h->6h",
                        "6h->7h",
                        "7h->8h",
                        "8h->9h",
                        "9h->10h",
                        "10h->11h",
                        "11h->12h",
                        "12h->13h",
                        "13h->14h",
                        "14h->15h",
                        "15h->16h",
                        "16h->17h",
                        "17h->18h",
                        "18h->19h",
                        "19h->20h",
                        "20h->21h",
                        "21h->22h",
                        "22h->23h",
                        "23h->0h",*/
                        "0h",
                        "1h",
                        "2h",
                        "3h",
                        "4h",
                        "5h",
                        "6h",
                        "7h",
                        "8h",
                        "9h",
                        "10h",
                        "11h",
                        "12h",
                        "13h",
                        "14h",
                        "15h",
                        "16h",
                        "17h",
                        "18h",
                        "19h",
                        "20h",
                        "21h",
                        "22h",
                        "23h",
                    ],
                    legend: {
                        position: "top",
                    },
                };
                return options;
            },
            graph_hist_timeline() {
                if (!this.$store.state.fleet.filteredAlertList && this.$store.state.fleet.updatingStats) return [];
                const fevs = {
                    name: this.$t("Falls during hour"),
                    data: [],
                    type: "column",
                };

                const bevs = {
                    name: this.$t("BedExits during hour"),
                    data: [],
                    type: "column",
                };

                const alerts = this.$store.state.fleet.filteredAlertList;

                for (let t = 0; t < 24; t++) {
                    const fallCount = alerts.filter(
                        (a) => t == new Date(a.date).getHours() && a.type === 5
                    );
                    const bedexitCount = alerts.filter(
                        (a) => t == new Date(a.date).getHours() && a.type === 4
                    );
                    fevs.data.push(fallCount.length);
                    bevs.data.push(bedexitCount.length);
                }
                return [fevs, bevs];
            },
        },
        mounted() {
        },
        methods: {
           
        }
    };
</script>


<style lang="scss" scoped>
</style>
