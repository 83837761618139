<template>
    <b-card
      no-body
      style="        
        margin-top: 0px;
        margin-bottom: 20px;
        background-color: whitesmoke;
      "
      title="Filter"
    >
      <h5>{{ $t('Insights Parameters') }}</h5>
       
      <div class="d-flex flex-row justify-content-left baseline fcontainer" style="align-items: center">
          <b-dropdown id="dropdown-form"
                      :text="timeRangeOptions.find(p => p.value === timeRange) ? timeRangeOptions.find(p => p.value === timeRange).text : timeRangeOptions[0].text"
                      ref="dropdown_timeRange"
                      class="m-2">
              <b-dropdown-item v-for="item of timeRangeOptions"
                               v-bind:key="item.text"
                               @click="timeRange = item.value"
                               href="#">{{item.text}}</b-dropdown-item>
          </b-dropdown>
          <span class="m-2 text-10 fitem" v-if="timeRange == 8">{{ $t('From') }}</span>
          <b-form-datepicker id="start-datepicker"
                             class="m-2 text-10"
                             v-model="customeStart"
                             :locale="$i18n.locale"
                             v-if="timeRange == 8"
                             size="sm"
                             style="max-width:200px"
                             >
          </b-form-datepicker>          
          <label for="end-datepicker" class="m-2 text-10" v-if="timeRange == 8">{{ $t('to') }}</label>
          <b-form-datepicker id="end-datepicker"
                             class="m-2 text-10 fitem"
                             v-model="customeEnd"
                             :locale="$i18n.locale"
                             size="sm"
                             v-if="timeRange == 8"  style="max-width:200px"></b-form-datepicker>
          <span class="m-2 text-10 fitem"  v-if="timeRange != 8">{{ $t('From') }} {{new Date(customeStart).toLocaleDateString($i18n.locale)}} {{ $t('to') }} {{new Date(customeEnd).toLocaleDateString($i18n.locale)}}</span>                             

          <b-dropdown id="dropdown-form"
                      :text="$t('Room Filter')"
                      ref="dropdown"
                      class="m-2 fitem">
              
                  <b-row><b-col cols="6"><b-button @click="changeAllRoomSelected(true)">{{$t('All')}}</b-button></b-col><b-col cols="6"><b-button @click="changeAllRoomSelected(false)">{{$t('None')}}</b-button></b-col></b-row>
                  
                  <template v-for="room in mRoomFilter">                      
                      <b-form-checkbox class="m-2" :key="room.oid" v-model="room.value"  @change="dirtyFilter=true">
                          {{ room.label }}
                      </b-form-checkbox>                      
                  </template>
              
          </b-dropdown>
          <b-dropdown id="dropdown-form"
                      :text="$t('Event Type Filter')"
                      ref="dropdown"
                      class="m-2 fitem">
              <b-row><b-col cols="6"><b-button @click="changeAllEventSelected(true)">{{$t('All')}}</b-button></b-col><b-col cols="6"><b-button @click="changeAllEventSelected(false)">{{$t('None')}}</b-button></b-col></b-row>                  
              <b-form-checkbox class="m-2"
                                v-model="showBedExit" @change="dirtyFilter=true">{{ $t('Bed Exit') }}</b-form-checkbox>
              <b-form-checkbox class="m-2"
                                v-model="showFall" @change="dirtyFilter=true">{{ $t('Fall') }}</b-form-checkbox>
              
          </b-dropdown>
          <b-dropdown id="dropdown-form"
                      :text="$t('Status Filter')"
                      ref="dropdown"
                      class="m-2 fitem">
              <b-row><b-col cols="6"><b-button @click="changeAllStatusSelected(true)">{{$t('All')}}</b-button></b-col><b-col cols="6"><b-button @click="changeAllStatusSelected(false)">{{$t('None')}}</b-button></b-col></b-row>
                  
                  <b-form-checkbox class="m-2" v-model="showTD" >{{ $t('True detections') }}</b-form-checkbox>
                  <b-form-checkbox class="m-2" v-model="showFA" >{{ $t('False alerts') }}</b-form-checkbox>
                  <b-form-checkbox class="m-2" v-model="showND" >{{ $t('Not trigged') }}</b-form-checkbox>
                  <b-form-checkbox class="m-2" v-model="showUN">{{ $t('Not yet verified') }}</b-form-checkbox>
                  <b-form-checkbox class="m-2" v-model="showTEST">{{ $t('Test Alerts') }}</b-form-checkbox>
              
          </b-dropdown>
          <b-button class="m-auto m-2 fitem" v-if="dirtyFilter" variant="primary" @click="applyFilter">
            {{ $t('Apply')}}
          </b-button>
          <b-button class="m-auto m-2 fitem" v-else  @click="downloadXls">
            {{ $t('Download results as Excel')}}
          </b-button>
      </div>

    </b-card>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import * as XLSX from '../../utils/xlsx/xlsx.mjs';

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Report filter"
  },
  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false,      
    },
  },
  computed: {
    allRoomSelected: {
      get: function() {
        for (const rs of this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization  && p.label.charAt(0) !== '_'))
          for (const room of rs.roomList)   {
            if(!this.mFilter.roomFilterValues.some(item => item.externalID === room.externalID)) {
              return false;
            }
          }                                   
        return true;
      }, set: function(val) {        
        let nSelect = [];
        if(val)
        {
          this.mAllRoomSelected = true;
          for (const rs of this.$store.state.fleet.roomsetList)
          for (const room of rs.roomList)   {
            nSelect.push([room.label, room.externalID]);            
          }                                   
        }
        
        this.mFilter.roomFilterValues = nSelect;
        this.dirtyFilter = true;        
      }
    },

    
    allStatusSelected: {
      get: function() {
        return this.mFilter.showND && this.mFilter.showFA && this.mFilter.showTD && this.mFilter.showUN && this.mFilter.showTEST;
      }, set: function(val) {        
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showND = val;
        filter.showFA = val;        
        filter.showTD = val;        
        filter.showUN = val;        
        filter.showTEST = val;        
        this.$store.dispatch("fleet/changeFilter", filter);
      }
    },

    allEventFilter: {
      get: function() {
        return this.mFilter.showFall && this.mFilter.showBedExit;
      }, set: function(val) {        
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showFall = val;
        filter.showBedExit = val;        
        this.$store.dispatch("fleet/changeFilter", filter);
      }
    },

    showFall: {
      get: function() { return this.$store.state.fleet.filter.showFall;  },
      set: function(val) {         
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showFall = val;
        this.$store.dispatch("fleet/changeFilter", filter);
      },
    },
    showBedExit: {
      get: function() { return this.$store.state.fleet.filter.showBedExit; },
      set: function(val) { 
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showBedExit = val;
        this.$store.dispatch("fleet/changeFilter", filter);
      },
    },
    showTD: {
      get: function() { return this.$store.state.fleet.filter.showTD;  },
      set: function(val) {         
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showTD = val;
        this.$store.dispatch("fleet/changeFilter", filter);
      },
    },
    showFA: {
      get: function() { return this.$store.state.fleet.filter.showFA;  },
      set: function(val) {         
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showFA = val;
        this.$store.dispatch("fleet/changeFilter", filter);
      },
    },
    showND: {
      get: function() { return this.$store.state.fleet.filter.showND;  },
      set: function(val) {         
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showND = val;
        this.$store.dispatch("fleet/changeFilter", filter);
      },
    },
        showUN: {
      get: function() { return this.$store.state.fleet.filter.showUN;  },
      set: function(val) {         
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showUN = val;
        this.$store.dispatch("fleet/changeFilter", filter);
      },
    },
        showTEST: {
      get: function() { return this.$store.state.fleet.filter.showTEST;  },
      set: function(val) {         
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showTEST = val;
        this.$store.dispatch("fleet/changeFilter", filter);
      },
    },


    timeRange: {
      // getter
      get: function () {
        return this.zzztimeRange;
      },
      // setter
        set: function (newValue) {            
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.timeRangeChoice = newValue;
        let nStart = moment();
        let nEnd = moment();
        if(![0,1,2,3,4,5,6,7,8].includes(newValue))
          newValue = 0;
        if (newValue === 0) {
          nStart = moment().add(-7, "days").toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 1) {
          nStart = moment().startOf("week").toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 2) {
          nStart = moment()
            .add(-7, "days")
            .startOf("week")
            .toISOString();
          nEnd = moment().startOf("week").toISOString();
        }
        if (newValue === 3) {
          nStart = moment()
            .add(-30, "days")
            .toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 4) {
          nStart = moment()
            .startOf("month")
            .toISOString();
          nEnd = moment().endOf("week").toISOString();
        }
        if (newValue === 5) {
          nStart = moment()
            .add(-1, "months")
            .startOf("months")
            .toISOString();
          nEnd = moment()
            .add(-1, "months")
            .endOf("months")
            .toISOString();
        }
        if (newValue === 6) {
          nStart = moment()
            .add(-3, "months")
            .toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 7) {
          nStart = moment()
            .add(-6, "months")
            .toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 8) {
          nStart = moment().add(-7, "days").toISOString();
            nEnd = moment().toISOString();
        }
        this.zzztimeRange = newValue;
        this.mFilter.dateFilter.start = nStart;
        this.mFilter.dateFilter.end = nEnd;
        this.dirtyFilter = true;
      },
    },

    customeStart: {
      get: function() { 
        let v = this.$store.state.fleet.filter.dateFilter.start;        
        return  v;
        },
      set: function(val) { 
          this.mFilter.dateFilter.start = val;          
          this.dirtyFilter = true;
      }
    },
    customeEnd: {
      get: function() {return this.$store.state.fleet.filter.dateFilter.end },
      set: function(val) {         
        this.mFilter.dateFilter.end = val;
        this.dirtyFilter = true;
      }
    }
  },
  data() {
    return {    
      zzztimeRange: 0,  
      timeRangeOptions: [
        { value: 0, text: this.$t("Last 7 days") },
        { value: 1, text: this.$t("This week") },
        { value: 2, text: this.$t("Last week") },
        { value: 3, text: this.$t("Last 30 days") },
        { value: 4, text: this.$t("This month") },
        { value: 5, text: this.$t("Last month") },
        { value: 6, text: this.$t("Last 3 months") },
        { value: 7, text: this.$t("Last 6 months") },
        { value: 8, text: this.$t("Custom") },
      ],
      mRoomFilter: [],
      mAllRoomSelected: false,
      statusFilter: {},      


      dirtyFilter: false,
      mFilter: {
        timeRangeChoice: 0,
        dateFilter: {
            start: moment().add(-7, "days").toISOString(),
            end: moment().toISOString(),
        },

        showFall: true,
        showBedExit: true,
        roomFilterValues: [],
        showND: true,
        showFA: false,
        showTD: true,
        showUN: true,
        showTEST: false,
      }
    };
  },
  mounted() {      
    for (const rs of this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization  && p.label.charAt(0) !== '_'))      
        for (const room of rs.roomList)               
            if(!this.mRoomFilter.find(r => room.oid === r.oid))
              this.mRoomFilter.push({ oid: room.oid, value: true, label: rs.label + '/' + room.label, externalID: room.externalID})

    // this.allRoomSelected = true;          
    // this.saveRoomFilter();    
    this.mFilter.roomFilterValues = this.mRoomFilter.filter(p => p.value).map(p => {
      return {
        oid: p.oid, 
        externalID: p.externalID
      }
    });
    //this.zzztimeRange = 0;
    //this.timeRange = this.zzztimeRange;
    this.mAllRoomSelected = true;    
    this.applyFilter();
    
  },
  methods: {    
    changeAllRoomSelected(t) {      
      let rss = this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization  && p.label.charAt(0) !== '_');      
      for (const rs of rss)      
        for (const room of rs.roomList)               
        {
          const p = this.mRoomFilter.find(r => room.oid === r.oid);
          if(!p)  this.mRoomFilter.push({ oid: room.oid, value: t, label: rs.label + '/' + room.label, externalID: room.externalID})
          else p.value = t;
          
        }                                    
      this.mFilter.roomFilterValues = this.mRoomFilter.filter(p => p.value).filter(p => p.value).map(p => {
        return {
          oid: p.oid, 
          externalID: p.externalID
        }
      });
      this.dirtyFilter = true;
    },

    changeAllStatusSelected(val) {
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showND = val;
        filter.showFA = val;
        filter.showTD = val;
        filter.showUN = val;
        filter.showTEST = val;
        this.$store.dispatch("fleet/changeFilter", filter);
    },
      
    changeAllEventSelected(val) {        
        let filter = JSON.parse (JSON.stringify ( this.$store.state.fleet.filter))
        filter.showFall = val;
        filter.showBedExit = val;        
        this.$store.dispatch("fleet/changeFilter", filter);
    },
    
    applyFilter() {      
      this.mFilter.roomFilterValues = this.mRoomFilter.filter(p => p.value).map(p => {
        return {
          oid: p.oid, 
          externalID: p.externalID
        }
      });
      this.zzztimeRange = this.zzztimeRange;
      this.dirtyFilter = false;
      this.$store.dispatch("fleet/changeFilter", this.mFilter);
    },
    makeStatus(d) {
        let res = 'Unkown';
        if(d.validation === 'UN') res = 'NOT_YET_CONFIRMED';
        else if(d.isTest) res = "SENSOR_TEST";
        else if(d.detection === 'FA') res = 'FALSE_ALERT';
        else if(d.detection === 'ND') res = 'NOT_NOTIFIED';
        else if(d.detection === 'TD') res = 'TRUE DETECTION';
        return res;
    },
    makeType(d) {
      let res = "Unkown";
      if(d.type == 5) res = 'FALL';
      if(d.type == 4) res = 'BED EXIT';
      if(d.type == 3) res = 'ORIENTATION CHANGE';
      return res;
    },
    downloadXls() {
      let xmap = this.$store.state.fleet.filteredAlertList.map((a) => { 
        let o = null;        
        try {
          o = {
            date_ISO: moment(a.date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            date: moment(a.date).format("YYYY-MM-DD"),
            time: moment(a.date).format("HH:mm:ss"),
            organization: a.organizationLabel,
            roomset: a.roomset,
            room: a.room,
            type: this.makeType(a),
            status: this.makeStatus(a),          
            videoAvailable: a.hasVideo && a.videoAvailable,
            reference: a.video,
            alertCount: a.chidren ? a.chidren.length : 1,
          };          
        } catch (error) {
          
        }     
        return o;           
      }).filter(r => r != null);

      try
      {        
        const ws = XLSX.utils.json_to_sheet(xmap)
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate file and send to client */
        XLSX.writeFile(wb, "isa_live_report.xlsx");
      } catch(error) {
        this.$bvToast.toast(this.$t('Error generating Excel') + ` ${error.message}`, {
                                title: this.$t('Error generating Excel'),
                                solid: true,
                                variant: 'warning',
                                duration: 5000
                            });
      }
      
    }
  },
  watch: {
    "$store.state.fleet.roomsetList": function(val) {
      for (const rs of val.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization))
        for (const room of rs.roomList)    
        {
          const p = this.mRoomFilter.find(r => room.oid === r.oid);

          if(!p) this.mRoomFilter.push({ oid: room.oid, value: true, label: rs.label + '/' + room.label, externalID: room.externalID});
          else p.value = true;
        }
      let nf = this.mRoomFilter.filter(p => p.value).map(p => p.oid).join(',');  
      let of = this.mFilter.roomFilterValues.join(',');
      this.mFilter.roomFilterValues = this.mRoomFilter.filter(p => p.value).map(p => {
        return {
          oid: p.oid, 
          externalID: p.externalID
        }
      });
      this.mFilter.roomFilterValues = this.mRoomFilter.filter(p => p.value).map(p => {
        return {
          oid: p.oid, 
          externalID: p.externalID
        }
      });
      this.dirtyFilter = of !== nf;
    },
    "$store.state.fleet.filter": function(val) {
      this.mFilter = JSON.parse(JSON.stringify(val));
      let tr = this.mFilter.timeRange;

    },

    "$store.state.fleet.currentOrganization": function (value) {  
        this.mRoomFilter = [];                
        for (const rs of this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization  && p.label.charAt(0) !== '_'))      
        for (const room of rs.roomList)               
            if(!this.mRoomFilter.find(r => room.oid === r.oid))
              this.mRoomFilter.push({ oid: room.oid, value: true, label: rs.label + '/' + room.label, externalID: room.externalID})

        this.mFilter.roomFilterValues = this.mRoomFilter.filter(p => p.value).map(p => {
          return {
            oid: p.oid, 
            externalID: p.externalID
          }
        });
        this.applyFilter();
    },
    
  }
};
</script>


<style lang="scss" scoped>

.b-dropdown-form {
    display: inline-block;
    padding: 0px;
    width: 200%;
    clear: both;
    font-weight: 400;
}

.fitem { flex-grow: 1; }
.fcontainer {
  display: flex;
  flex-wrap: wrap;
}

</style>
