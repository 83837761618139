<template>
  <div  v-if="!$store.state.fleet.updatingStat">
      <div class="d-flex flex-row justify-content-left baseline" style="align-items: center">
          <b-dropdown id="dropdown-form"
                      :text="timeRangeOptions.find(p => p.value === timeRange) ? timeRangeOptions.find(p => p.value === timeRange).text : timeRangeOptions[0].text"
                      ref="dropdown_timeRange"
                      class="m-2">
              <b-dropdown-item v-for="item of timeRangeOptions"
                               v-bind:key="item.text"
                               @click="timeRange = item.value"
                               href="#">{{item.text}}</b-dropdown-item>
          </b-dropdown>
          <span class="m-2 text-10" >{{ $t('From') }}</span>
          <b-form-datepicker id="start-datepicker"
                             class="m-2  text-10"
                             v-model="customeStart"
                             :locale="$i18n.locale"
                             v-if="timeRange == 8"></b-form-datepicker>
          <span v-else class="m-2 text-10">{{new Date(customeStart).toLocaleDateString($i18n.locale)}}</span>
          <label for="end-datepicker" class="m-2  text-10">{{ $t('to') }}</label>
          <b-form-datepicker id="end-datepicker" :locale="$i18n.locale" class="m-2  text-10" v-model="customeEnd" v-if="timeRange == 8"></b-form-datepicker>
          <span v-else class="m-2  text-10">{{new Date(customeEnd).toLocaleDateString($i18n.locale)}}</span>
          <b-button  variant="primary" style="color: black" v-if="!$store.state.user.isMobile && !loadingReport" class="ml-auto p-2" @click="downloadXls">
              <span v-if="!loadingReport"> {{$t('Download ISA Report')}}</span>
          </b-button>
          <div v-if="loadingReport" class="ml-auto m-2">
              <span>{{ $t('Preparing report, it can take few minutes...') }}</span>
          </div>          
      </div>

    <b-row>
      <b-col cols="6" class="card1">
        <div>
          <h3>{{ $t('ISA Detections') }}</h3>
                  <b-row>

          <b-col lg="6" md="6" sm="6" class="card1">
            <b-card class="card-icon-big text-center mb-30 card1" >
              <i class="i-Vector"></i>
              <p class="text-muted mt-2 mb-2">{{ $t('Estimated Fall Count') }} <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAStatfallEstimatedCount')"/></p>
              <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-18 mt-2 mb-0">{{$store.state.fleet.filteredStats.fallEstimatedCount}}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              <!--<font-awesome-icon icon="wrench" variant="warning" class="bottom-right hcard1" ></font-awesome-icon>-->
            </b-card>
          </b-col>
          <b-col lg="6" md="6" sm="6">
            <b-card class="card-icon-big text-center mb-30 card3">
              <i class="i-Communication-Tower"></i>
              <p class="text-muted mt-2 mb-2">{{ $t('Total Fall Alerts') }} <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAStatfallAlertCount')"/></p>
              <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-18 mt-2 mb-0">{{$store.state.fleet.filteredStats.fallAlertCount}}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              <!--<font-awesome-icon  icon="wrench" variant="warning" class="bottom-right hcard3" ></font-awesome-icon>-->
            </b-card>
          </b-col>


          <b-col lg="6" md="6" sm="6">
            <b-card class="card-icon text-center mb-30 card2">
              <i class="i-Communication-Tower-2"></i>
              <p class="text-muted mt-2 mb-2">{{ $t('Total BedExit Alerts') }} <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAStatbedExitAlertCount')"/></p>                                          
              <p v-if="$store.state.fleet.filteredStats" class="text-primary text-24 line-height-1 m-0">{{$store.state.fleet.filteredStats.bedExitAlertCount }}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              <!--<font-awesome-icon  icon="wrench" variant="warning" class="bottom-right hcard2"  ></font-awesome-icon>-->
            </b-card>
          </b-col>
          
          

          <b-col lg="6" md="6" sm="6">
            <b-card class="card-icon-big text-center mb-30 card4" >
              <i class="i-Folder-Hide"></i>
              <p class="text-muted mt-2 mb-2">{{ $t('Total False Alerts') }} <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAStatfalseAlertsCount')"/></p>
              <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-18 mt-2 mb-0">{{$store.state.fleet.filteredStats.falseAlertsCount}}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              <!--<font-awesome-icon  icon="wrench" variant="warning" class="bottom-right hcard4" ></font-awesome-icon>-->
            </b-card>
          </b-col>
        </b-row>                   
        </div>



      </b-col>
      <b-col cols="6" class="card1" style="border-left: 1px solid #88cabe;">        
        <div>
          <h3>ISA Notifications</h3>
          <b-row>

          <b-col lg="6" md="6" sm="6" class="card1">
            <b-card class="card-icon-big text-center mb-30 card1" >
              <i class="i-Telephone"></i>
              <p class="text-muted mt-2 mb-2">{{ $t('Total Calls') }} <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAStatcallsCount')"/></p>
              <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-18 mt-2 mb-0">{{$store.state.fleet.filteredStats.callsCount}}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              <!--<font-awesome-icon icon="wrench" variant="warning" class="bottom-right hcard1" ></font-awesome-icon>-->
            </b-card>
          </b-col>
          <b-col lg="6" md="6" sm="6">
            <b-card class="card-icon text-center mb-30 card2">
              <i class="i-Speak"></i>
              <p class="text-muted mt-2 mb-2">{{ $t('Total unanswered Calls') }} <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAStatcallsUnansweredCount')"/></p>                                          
              <p v-if="$store.state.fleet.filteredStats" class="text-primary text-24 line-height-1 m-0">{{$store.state.fleet.filteredStats.callsUnansweredCount}}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              <!--<font-awesome-icon  icon="wrench" variant="warning" class="bottom-right hcard2"  ></font-awesome-icon>-->
            </b-card>
          </b-col>
          
          <b-col lg="6" md="6" sm="6" v-if="responseTime != 'NaN seconds'">
            <b-card class="card-icon-big text-center mb-30 card3">
              <i class="i-Timer1"></i>
              <p class="text-muted mt-2 mb-2">{{ $t('Median Call Reponse Time') }} <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAStatreponseTime')"/> <b-button v-b-toggle.collapse-2 size="sm" variant="link"><span class="when-open">{{$t('less...')}}</span><span class="when-closed">{{$t('more...')}}</span></b-button></p>
              <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-18 mt-2 mb-0">{{humanizeDuration(this.$store.state.fleet.filteredStats.callsResponseTime.median)}}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>

              <!-- Element to collapse -->
              <b-collapse id="collapse-2">      
                  <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-12 mt-2 mb-0">{{$t('Average')}}: {{humanizeDuration(this.$store.state.fleet.filteredStats.callsResponseTime.average)}}</p>
                  <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-12 mt-2 mb-0">{{$t('Median')}}: {{humanizeDuration(this.$store.state.fleet.filteredStats.callsResponseTime.median)}}</p>
                  <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-12 mt-2 mb-0">{{$t('Standard deviation')}}: {{humanizeDuration(this.$store.state.fleet.filteredStats.callsResponseTime.stddev)}}</p>
              </b-collapse>


              <!--<font-awesome-icon  icon="wrench" variant="warning" class="bottom-right hcard3" ></font-awesome-icon>-->
            </b-card>
          </b-col>

          
          <b-col lg="6" md="6" sm="6" v-if="responseTime != 'NaN seconds'">
            <b-card class="card-icon-big text-center mb-30 card4" >
              <i class="i-Time-Window"></i>
              <p class="text-muted mt-2 mb-2">{{ $t('Median Alerting Time') }} <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAStatalertingTime')"/> <b-button v-b-toggle.collapse-3  size="sm" variant="link"><span class="when-open">{{$t('less...')}}</span><span class="when-closed">{{$t('more...')}}</span></b-button></p>
              <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-18 mt-2 mb-0">{{humanizeDuration($store.state.fleet.filteredStats.alertingResponseTime.median)}}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              
              <!-- Element to collapse -->
              <b-collapse id="collapse-3">      
                  <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-12 mt-2 mb-0">{{$t('Average')}}: {{humanizeDuration($store.state.fleet.filteredStats.alertingResponseTime.average)}}</p>
                  <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-12 mt-2 mb-0">{{$t('Median')}}: {{humanizeDuration($store.state.fleet.filteredStats.alertingResponseTime.median)}}</p>
                  <p v-if="$store.state.fleet.filteredStats" class="line-height-1 text-title text-12 mt-2 mb-0">{{$t('Standard deviation')}}: {{humanizeDuration($store.state.fleet.filteredStats.alertingResponseTime.stddev)}}</p>
              </b-collapse>

            </b-card>
          </b-col>
        </b-row>   
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
  <div v-else>
      {{ $t('Loading...') }}
  </div>
</template>

<script>
import moment from "moment";
import { API_URL } from '../../../.env'
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Insights"
  },
  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      timeRangeOptions: [
        { value: 0, text: this.$t("Last 7 days") },
        { value: 1, text: this.$t("This week") },
        { value: 2, text: this.$t("Last week") },
        { value: 3, text: this.$t("Last 30 days") },
        { value: 4, text: this.$t("This month") },
        { value: 5, text: this.$t("Last month") },
        { value: 6, text: this.$t("Last 3 months") },
        { value: 7, text: this.$t("Last 6 months") },
        { value: 8, text: this.$t("Custom") }
      ],
      zzztimeRange: 0,
      API_URL,
      loadingReport: false,
      mRoomFilter: [],
       mFilter: {
        timeRangeChoice: 7,
        dateFilter: {
            start: moment().add(-6, "months").toISOString(),
            end: moment().toISOString(),
        },

        showFall: true,
        showBedExit: true,
        roomFilterValues: [],
        showND: true,
        showFA: true,
        showTD: true,
        showUN: false,
        showTEST: false,
      }
    };
  },
  computed: {
    roomstats() {
      const roomstatsFields = [];
      roomstatsFields.push({
        key: "roomLabel",
        label: this.$t("Room"),
        sortable: true
      });
      roomstatsFields.push({
        key: "totalFalls",
        label: this.$t("Estimated Fall Count"),
        sortable: true
      });
      roomstatsFields.push({
        key: "totalAlerts",
        label: this.$t("Fall Alert Count"),
        sortable: true
      });
      roomstatsFields.push({
        key: "totalBedExit",
        label: this.$t("Bed Exit Alert Count"),
        sortable: true
      });
      return roomstatsFields;
    },

    timeRange: {
      // getter
      get: function() {
        return this.zzztimeRange;
      },
      // setter
      set: function(newValue) {                
        this.zzztimeRange = newValue;
        let nStart = moment();
        let nEnd = moment();
        if(![0,1,2,3,4,5,6,7,8].includes(newValue))
          newValue = 0;
        if (newValue === 0) {
          nStart = moment().add(-7, "days").toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 1) {
          nStart = moment().startOf("week").toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 2) {
          nStart = moment()
            .add(-7, "days")
            .startOf("week")
            .toISOString();
          nEnd = moment().startOf("week").toISOString();
        }
        if (newValue === 3) {
          nStart = moment()
            .add(-30, "days")
            .toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 4) {
          nStart = moment()
            .startOf("month")
            .toISOString();
          nEnd = moment().endOf("week").toISOString();
        }
        if (newValue === 5) {
          nStart = moment()
            .add(-1, "months")
            .startOf("months")
            .toISOString();
          nEnd = moment()
            .add(-1, "months")
            .endOf("months")
            .toISOString();
        }
        if (newValue === 6) {
          nStart = moment()
            .add(-3, "months")
            .toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 7) {
          nStart = moment()
            .add(-6, "months")
            .toISOString();
          nEnd = moment().toISOString();
        }
        if (newValue === 8) {
          nStart = moment().add(-7, "days").toISOString();
            nEnd = moment().toISOString();
        }
        this.mFilter.dateFilter.start = nStart;
        this.mFilter.dateFilter.end = nEnd;  
        
        this.$store.dispatch("fleet/changeFilter", this.mFilter);
      }
    },
    customeStart: {
      get: function() {
        let v = this.$store.state.fleet.filter.dateFilter.start;
        return v;
      },
      set: function(val) {
        this.mFilter.dateFilter.start = val;                
        this.$store.dispatch("fleet/changeFilter", this.mFilter);
      }
    },
    customeEnd: {
      get: function() {
        return this.$store.state.fleet.filter.dateFilter.end;
      },
      set: function(val) {         
        this.mFilter.dateFilter.end = val;          
        this.$store.dispatch("fleet/changeFilter", this.mFilter);
      }
    },

    responseTime() {
        let tm = 60;
        let t = this.$store.state.fleet.filteredStats.callsResponseTime.median;
        if(t > 60) {
          let m = Math.floor(t / tm);
          let s = t - m*tm;
          return `${m} ${this.$t('minutes')} ${Math.floor(s)} ${this.$t('seconds')}`
        }
        if(isNaN(t)) return this.$t('Not available');
        return `${Math.floor(t)} ${this.$t('seconds')}`;
    },
    alertResponseime() {
        let tm = 60;
        let t = this.$store.state.fleet.filteredStats.callsResponseTime.median;
        if(t > 60) {
          let m = Math.floor(t / tm);
          let s = t - m*tm;
          return `${m} ${this.$t('minutes')} ${Math.floor(s)} ${this.$t('seconds')}`
        }
        if(isNaN(t)) return this.$t('Not available');
        return `${Math.floor(t)} ${this.$t('seconds')}`;
    }
  },
  mounted() {
    for (const rs of this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization  && p.label.charAt(0) !== '_'))      
        for (const room of rs.roomList)               
            if(!this.mRoomFilter.find(r => room.oid === r.oid))
              this.mRoomFilter.push({ oid: room.oid, value: true, label: rs.label + '/' + room.label, externalID: room.externalID})

    this.mFilter.roomFilterValues = this.mRoomFilter.filter(p => p.value).map(p => {
      return {
        oid: p.oid, 
        externalID: p.externalID
      }
    });
    this.timeRange = 7;
    this.zzztimeRange = 7;
    this.applyFilter();
    
  },
  methods: {
    downloadXls() {
      this.loadingReport = true;
      const self = this;
      const url = `${API_URL.url}/report/downloadLive?org=${this.$store.state.fleet.currentOrganization}&start=${this.$store.state.fleet.filter.dateFilter.start}&end=${this.$store.state.fleet.filter.dateFilter.end}&lang=${this.$i18n.locale}`;
      console.log(url);
       fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.user.currentUser.jwt
          }
        })
        .then(function(response) {
          return response.arrayBuffer();
        })
        .then(function(data) {
          // create a blob url representing the data
          var blob = new Blob([data]);
          var url = window.URL.createObjectURL(blob);  // attach blob url to anchor element with download attribute
          var anchor = document.createElement('a');
          anchor.setAttribute('href', url);
          anchor.setAttribute('download', `ISA_REPORT_${self.$store.state.fleet.currentOrganization}_${self.$store.state.fleet.filter.dateFilter.start}_${self.$store.state.fleet.filter.dateFilter.end}.xlsx`);
          anchor.click();
            window.URL.revokeObjectURL(url);
        }).finally(() => {
            this.loadingReport = false;
        })
    
    },
    humanizeDuration(t) {
        let tm = 60;
        if(t > 60) {
          let m = Math.floor(t / tm);
          let s = t - m*tm;
          return `${m} ${this.$t('minutes')} ${Math.floor(s)} ${this.$t('seconds')}`
        }
        if(isNaN(t)) return this.$t('Not available');
        return `${Math.floor(t)} ${this.$t('seconds')}`;
    },
    applyFilter() {      
      this.mFilter.roomFilterValues = this.mRoomFilter.filter(p => p.value).map(p => {
        return {
          oid: p.oid, 
          externalID: p.externalID
        }
      });
      this.dirtyFilter = false;
      this.$store.dispatch("fleet/changeFilter", this.mFilter);
    }
  },
  watch: {
    "$store.state.fleet.currentOrganization": function (value) {                  
        this.$store.dispatch("fleet/changeFilter", this.mFilter);
    },
    "$store.state.fleet.filter": function(val) {
      this.mFilter = JSON.parse(JSON.stringify(val));
    }
  }
    
};
</script>


<style lang="scss" scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
