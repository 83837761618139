<template>
  <b-modal hide-footer v-model="showCQSEditor" @hidden="close" :title="$t('Continuous Improvement Actions')">
    <div>
      <b-card>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
            <b-form @submit.prevent="handleSubmit(apply)">
                <validation-provider :name="$t('Title')"                                     
                                     :rules="{ required: true, min: 3, max: 200 }"
                                     v-slot="validationContext">
                    <b-form-group label-cols-sm="3"
                                  :label="$t('Title:')"
                                  label-align-sm="left"
                                  label-for="title">
                        <b-form-input v-model="title"
                                      :state="getValidationState(validationContext)"
                                      aria-describedby="input-1-live-feedback"></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider :name="$t('Description')"
                                     :rules="{ required: false, min: 3, max: 20000 }"
                                     v-slot="validationContext">
                    <b-form-group label-cols-sm="3"
                                  :label="$t('Description:')"
                                  label-align-sm="left"
                                  label-for="description">
                        <b-form-textarea v-model="description"
                                      :state="getValidationState(validationContext)"
                                      aria-describedby="input-1-live-feedback"></b-form-textarea>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider :name="$t('Date')" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label-cols-sm="3"
                                  :label="$t('Start Date:')"
                                  label-align-sm="left"
                                  label-for="date">
                       <b-form-datepicker id="end-datepicker"
                             class="m-2"
                             v-model="date"
                             :locale="$i18n.locale"
                             ></b-form-datepicker>
                        <b-form-invalid-feedback id="input-3-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <hr />
                <validation-provider
              :name="$t('Notification Type')"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
            <b-form-group
              label-cols-sm="3"
              :label="$t('Action Scope:')"
              label-align-sm="left"
              class="mb-0"
              label-for="scope"
            >
              <b-form-radio-group class="pt-2" 
              :options="scopes" 
              v-model="scope"
              :state="getValidationState(validationContext)"
              aria-describedby="input-2-live-feedback"
              ></b-form-radio-group>
              <span class="invalid-feedback"
                  id="input-2-live-feedback" style="display: block;"
                >{{ validationContext.errors[0] }}</span>
            </b-form-group>
            </validation-provider>

            <b-dropdown id="dropdown-form"
                      v-if="scope > 0"
                      :text="selectedRoomset ? selectedRoomset.label : ''"
                      ref="dropdown_timeRange"
                      class="m-2">
              <b-dropdown-item v-for="item of $store.state.fleet.roomsetList.filter(p => p.organizationLabel === $store.state.fleet.currentOrganization && p.label.charAt(0) !== '_')"
                               v-bind:key="item.label"
                               @click="selectedRoomset = item"
                               href="#">{{item.label}}</b-dropdown-item>
          </b-dropdown>

          <b-dropdown id="dropdown-form"
                      :text="selectedRoom ? selectedRoom.label : ''"
                      v-if="scope == 2 && selectedRoomset"
                      ref="dropdown_timeRange"
                      class="m-2">
              <b-dropdown-item v-for="item of (selectedRoomset ? selectedRoomset.roomList : [])"
                               v-bind:key="item.label"
                               @click="selectedRoom = item"
                               href="#">{{item.label}}</b-dropdown-item>
          </b-dropdown>

                <hr />


                <b-button class="float-left" @click="close">
                    {{ $t('Cancel')}}
                </b-button>
                <b-button :loading="requesting" variant="primary" class="float-right" type="submit">
                    {{ $t('Apply')}}
                </b-button>


            </b-form>
        </validation-observer>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import { AuthService } from "@/services/auth.service";
import { API_URL } from '@/.env'
import { SPService } from "@/services/sp.service";
 
export default {
  components: {
     
  },
  data() {
    return {
      oid: '',
      date: null,
      dateEnd: null,
      title: null,
      description: null,
      scope: 0,
      scopes: [
        { value: 0, text: this.$t("Organization") },
        { value: 1, text: this.$t("Care Unit") },
        { value: 2, text: this.$t("Room") },        
      ],
      showCQSEditor: false,
      selectedRoomset: null,
      selectedRoom: null,

      create: false,

      
    };
  },
  computed: {
    options() {
      return this.$store.state.fleet.roomsetList.filter(r => r.organizationLabel === this.$store.state.fleet.currentOrganization && r.label.charAt(0) !== '_').map(p => {
        return { name: p.label };
      });
    }
  },
  watch: {
    "$store.state.user.cqsEditorShow": function(val) {      
      this.showCQSEditor = val;
      this.loadCQS();          
    },
    "$store.state.user.cqsEditorData": function(user) {
      if(user) {
        this.create = user.create;
        this.loadCQS();           
      }
           
    }
  },
  methods: {
    
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    close() {
     this.$store.commit("user/SHOW_CQS", null);      
    },
    loadCQS() {
      if(this.$store.state.fleet.roomsetList && this.$store.state.fleet.roomsetList.length)
        {
           let rss = this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization  && p.label.charAt(0) !== '_');
            
            for(let rs of rss)
                if(rs.roomList && rs.roomList.length)
                    {
                      this.selectedRoomset = rs;
                      this.selectedRoom = rs.roomList[0];
                    }
        }
      
      if (this.$store.state.user.cqsEditorData) {
        this.oid = this.$store.state.user.cqsEditorData.oid;
        this.requesting = false;
        this.title = this.$store.state.user.cqsEditorData.title;
        this.description = this.$store.state.user.cqsEditorData.description;
        this.date = this.$store.state.user.cqsEditorData.date;
        this.dateEnd = this.$store.state.user.cqsEditorData.dateEnd;
        this.roomOid = this.$store.state.user.cqsEditorData.roomOid;
        this.roomsetOid = this.$store.state.user.cqsEditorData.roomsetOid;

        if(this.$store.state.user.cqsEditorData.roomsetOid && this.$store.state.user.cqsEditorData.roomOid)         
          this.scope = 2;
        if(this.$store.state.user.cqsEditorData.roomsetOid && !this.$store.state.user.cqsEditorData.roomOid)  
          this.scope = 1;
        if(!this.$store.state.user.cqsEditorData.roomsetOid && !this.$store.state.user.cqsEditorData.roomOid)  
          this.scope = 0;

        if(this.roomsetOid)
          for(let rs of this.$store.state.fleet.roomsetList.filter(r => r.label.charAt(0) !== '_'))
          {
            if(rs.oid === this.roomsetOid)
            {
              this.selectedRoomset = rs;
              if(this.roomOid)
                for(let r of rs.roomList) {
                  if(r.oid === this.roomOid)
                    this.selectedRoom = r;
                }
            }                    
          }
      }
    },
    validatePhone({ number, isValid, country }) {
      console.log(number);
      console.log(isValid);
      console.log(country);
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.value.push(tag);
      console.log(this.value);
    },
    async apply() {
      // here we know that the form is validated
      this.requesting = true

      let payload = {     
              "oid": this.oid,         
              "title": this.title,
              "description": this.description,
              "date": this.date,
              "roomOid": this.selectedRoom && this.scope === 2 ? this.selectedRoom.oid : '',
              "roomLabel": this.selectedRoom && this.scope === 2 ? this.selectedRoom.label : '',
              "roomsetOid": this.selectedRoomset && this.scope > 0 ? this.selectedRoomset.oid : '',
              "roomsetLabel": this.selectedRoomset && this.scope > 0 ? this.selectedRoomset.label : '',
              "organizationLabel": this.$store.state.fleet.currentOrganization,
              "dateEnd": this.dateEnd,              
          } ;
  
      if(this.create) { // here we create a new user
        // get first roomsetOID      
        let answer = await SPService.createCQS(API_URL.url, this.$store.state.user.currentUser.jwt, payload);        
        
      }
      else {
        
        let answer = await SPService.updateCQS(API_URL.url, this.$store.state.user.currentUser.jwt, payload);                  
        
      }
      // handle user rights
      this.$store.dispatch('fleet/loadCQS')      
      this.$store.commit("user/SHOW_CQS", null);
    }
  },
  mounted() {    
      this.requesting = false;
      this.changingPassword = false;
      this.showPassword = false;
      this.$store.commit("user/SHOW_CQS", null);      
  },
  name: "UserEditionModal"
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
</style>
