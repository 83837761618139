<template>
    <div v-if="!$store.state.fleet.updatingStat || $store.state.fleet.infraList.length === 0">
      <h5>{{ $t('List of sensors connectivity issues since the last 2 months.') }}</h5>
        <ISASensorConnectivityHistory/>
        <b-row style="margin-top: 1rem;">
            <b-col cols="6" v-for="sensor of chartOptions" v-bind:key="sensor.room_label">
             <b-card class="card-icon-big text-center mb-30 card1" >
                <h4>{{sensor.room_label}}</h4>
                <b-row>
                    <b-col class="cols-sm-12 cols-md-4">
                        <span v-if="!$store.state.user.isMobile && sensor.downs === 0" style="color: #88cabe">{{ $t('No connectivity issue') }}</span>
                        <template v-else>
                            <span style="color: #orange" v-if="!$store.state.user.isMobile">{{ $t('Disconnected during {duration}', { duration: sensor.downsStr}) }}</span>
                            <br/>
                            <b-button @click="showSensorConnectivityHistory(sensor)" size="sm">{{ $t('View Details...') }}</b-button>

                        </template>

                    </b-col>
                    <b-col class="cols-sm-12 cols-md-8">
                        <div class="chart-wrapper" style="height: 200px">
                            <v-chart :options="sensor" :autoresize="true"></v-chart>
                        </div>
                    </b-col>
                    <b-col lg="12" xl="12" md="12">
            
                    </b-col>
                </b-row>   
                </b-card>             
            </b-col>
        </b-row>
    </div>
    <div v-else>
        {{ $t('Loading...')}}
    </div>
</template>

<script>

    import moment from 'moment';
    import ISASensorConnectivityHistory from './ISASensorConnectivityHistory';

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Insights"
        },
        components: {
            ISASensorConnectivityHistory
        },
  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false,      
    },
  },
  data() {
    return {
      timeRangeOptions: [
        { value: 0, text: this.$t("Last 7 days") },
        { value: 1, text: this.$t("This week") },
        { value: 2, text: this.$t("Last week") },
        { value: 3, text: this.$t("Last 30 days") },
        { value: 4, text: this.$t("This month") },
        { value: 5, text: this.$t("Last month") },
        { value: 6, text: this.$t("Last 3 months") },
        { value: 7, text: this.$t("Last 6 months") },
        { value: 8, text: this.$t("Custom") },
      ],
      zzztimeRange: 0,
    }
  },
  computed: {      
      roomstats() {
          const roomstatsFields = [];
          roomstatsFields.push({ key: "roomLabel", label: this.$t('Room'), sortable: true });
          roomstatsFields.push({ key: "totalFalls", label: this.$t('Estimated Fall Count'), sortable: true });
          roomstatsFields.push({ key: "totalAlerts", label: this.$t('Fall Alert Count'), sortable: true });
          roomstatsFields.push({ key: "totalBedExit", label: this.$t('Bed Exit Alert Count'), sortable: true });
          return roomstatsFields;                
      },

      timeRange: {
      // getter
      get: function () {
        return  this.$store.state.fleet.filter.timeRangeChoice;
      },
      // setter
      set: function (newValue) {
        // this.$store.commit('fleet/SET_ROOM_FILTER_TIMERANGECHOICE', newValue)        
      },
    },
    customeStart: {
      get: function() { 
        let v = this.$store.state.fleet.filter.dateFilter.start;        
        return  v;
        },
      set: function(val) { }
    },
    customeEnd: {
      get: function() {return this.$store.state.fleet.filter.dateFilter.end },
      set: function(val) { }
    },

    chartOptions() {
      let charts = [];
      for(const i of this.$store.state.fleet.infraList)
      {
        const d =
        {
            room_label: i.room_label,
            oid: i.oid,
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true
            },
            color: ["#88cabe", "#ced4da"],
            /*tooltip: {
              show: true,
              trigger: "item",
              formatter: "{a} <br/>{b}: {c} ({d}%)"
            },*/
            xAxis: [
              {
                axisLine: {
                  show: false
                },
                splitLine: {
                  show: false
                }
              }
            ],
            yAxis: [
              {
                axisLine: {
                  show: false
                },
                splitLine: {
                  show: false
                }
              }
            ],

            series: [
              {
                name: this.$t("Connected"),
                type: "pie",
                radius: ["70%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                hoverOffset: 5,
                label: {
                  normal: {
                    show: true,
                    position: "center",
                    textStyle: {
                      fontSize: this.$store.state.user.isMobile ? "10" : "12",
                      fontWeight: "normal"
                    },
                    formatter: "{a}"
                  },
                  /*
                  emphasis: {
                    show: true,
                    textStyle: {
                      fontSize: "10",
                      fontWeight: "bold"
                    },
                    formatter: "{b}"
                  }*/
                },
                labelLine: {
                  normal: {
                    show: false
                  }
                },
                data: [{value: 17640, name: this.$t("Hours Connected")}, {value: 360, name: this.$t("Hours Disconnected.") }],
                itemStyle: {
                  emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)"
                  }
                }
              }
            ]
        };
        let downs = i.events.filter(e => e.sensor_uptime === 0).map(e => moment(e.end).diff(moment(e.start), 'seconds')).reduce((a, b) => a + b, 0)
        let ups = i.events.filter(e => e.sensor_uptime > 0).map(e => moment(e.end).diff(moment(e.start), 'seconds')).reduce((a, b) => a + b, 0)
        let p = ups / (ups + downs);
        p = 100*p;
        d.series[0].name = p.toFixed('00') + this.$t('% connected');
        d.series[0].data = [
          { name: this.$t('Hours Up'), value: ups / (60 * 60)},
          { name: this.$t('Hours Down'), value: downs / (60 * 60)},
        ]
        d.upsStr = this.humanizeTime(ups);
        d.downsStr = this.humanizeTime(downs);
        d.ups = (ups);
        d.downs = (downs);
        d.downEvents = i.events.filter(e => e.sensor_uptime === 0);
        charts.push(d);
    }
    return charts;
    },
  },
  mounted() {    
  },
  methods: {
      showSensorConnectivityHistory(sensor) {          
          this.$store.dispatch('fleet/watchSensorConnectionHistory',sensor)
      },
      humanizeTime(diff, d2) {
        if(d2) {
          diff = moment(d2).diff(moment(diff), 'seconds')
        }
        const tM = (30 * 24 * 60 * 60 );      
        const tD = ( 1 * 24 * 60 * 60 );
        const tH = (1 * 1 * 60 * 60 );
        const tm = (1 * 1 * 1 * 60 );
        const ts = (1 * 1 * 1 * 1 );      

        const diffM = Math.floor((diff) / tM);
        const diffD = Math.floor((diff-diffM*tM) / tD);      
        const diffH = Math.floor((diff-diffM*tM - diffD*tD) / tH);
        const diffm = Math.floor((diff-diffM*tM - diffD*tD - diffH*tH) / tm);
        const diffS = Math.floor((diff-diffM*tM - diffD*tD - diffH*tH - diffm*tm) / ts);
                
        return   (diffM > 0? `${diffM} ${this.$t('months')} ` : '')
                            +  (diffD > 0? `${diffD}  ${this.$t('days')} ` : '')
                            +  (diffH > 0? `${diffH}  ${this.$t('hours')} ` : '')
                            +  (diffm > 0? `${diffm}  ${this.$t('minutes')} ` : '')
                            +  (diffS > 0? `${diffS}  ${this.$t('seconds')}` : '');
      }
  }
};
</script>


<style lang="scss" scoped>
.isaul {
  padding: 0px;
}

</style>