<template>
  <div>
    <h5>{{ $t('List of alerts per room during the last 2 months.') }}</h5>
    <b-row style="margin-top: 1rem;">
      <b-col lg="12" xl="12" md="12">
    <b-table
      hover
      sm
      border
      striped
      bordered
      responsive
      light
      compact
      class="text-center"
      :items="$store.state.fleet.roomSummary.filter(p => p.roomsetLabel.charAt(0) !== '_')"            
      :fields="roomstats"
      style="margin: 10px"
      v-if="$store.state.fleet.roomSummary"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>{{ $t('Loading...') }}</strong>
        </div>
      </template>
      <template v-slot:cell(roomLabel)="row">
          <span v-if="row">{{ row.item.roomsetLabel + '/' + row.item.roomLabel }}</span>
      </template>
    
      <template v-slot:head(totalFalls)="data">
        <span>{{ data.label }}</span>
        <font-awesome-icon style="margin-left: 1rem;" icon="question-circle" @click.stop="$store.commit('user/NEEDS_HELP','ISAInsightRoomTotalFalls')"/>
      </template>
      <template v-slot:head(fallRate)="data">
        <span>{{ data.label }}</span>
        <font-awesome-icon style="margin-left: 1rem;" icon="question-circle" @click.stop="$store.commit('user/NEEDS_HELP','ISAInsightRoomFallRate')"/>
      </template>
      <template v-slot:cell(fallRate)="row">
          <span v-if="row && row.item.fallRateMonths > 0">{{ row.item.fallRateMonths + " " + $t('month')}} </span>
          <span v-if="row && row.item.fallRateDays > 0">{{ row.item.fallRateDays + " " + $t('days')}} </span>
          <span v-if="row && row.item.fallRateHours > 0">{{ row.item.fallRateHours + " " + $t('hours')}} </span>
      </template>
      
      <template v-slot:head(totalBedExit)="data">
        <span>{{ data.label }}</span>
        <font-awesome-icon style="margin-left: 1rem;" icon="question-circle" @click.stop="$store.commit('user/NEEDS_HELP','ISAInsightRoomTotalBedExit')"/>
      </template>
    </b-table>
        <!--<b-row>
          <b-col lg="6" md="6" sm="6" class="card1">
            <b-card class="card-icon-big text-center mb-30 card1"  @click="$router.push('/app/roommanagement')">
              <i class="i-Hospital"></i>
              <p class="text-muted mt-2 mb-2">Total Care Units</p>
              <p v-if="$store.state.fleet.roomsetList && $store.state.fleet.roomsetList.length" class="line-height-1 text-title text-18 mt-2 mb-0">{{$store.state.fleet.roomsetList ? $store.state.fleet.roomsetList.filter(p => p.organizationLabel === $store.state.fleet.currentOrganization).length : '?' }}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>            
            </b-card>
          </b-col>
        </b-row>
        -->
      </b-col>
    </b-row>
    </div>
</template>

<script>

import moment from 'moment';

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Insights"
  },
  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false,      
    },
  },
  data() {
    return {
      timeRangeOptions: [
        { value: 0, text: this.$t("Last 7 days") },
        { value: 1, text: this.$t("This week") },
        { value: 2, text: this.$t("Last week") },
        { value: 3, text: this.$t("Last 30 days") },
        { value: 4, text: this.$t("This month") },
        { value: 5, text: this.$t("Last month") },
        { value: 6, text: this.$t("Last 3 months") },
        { value: 7, text: this.$t("Last 6 months") },
        { value: 8, text: this.$t("Custom") },
      ],
      zzztimeRange: 0,
    }
  },
  computed: {      
      roomstats() {
          const roomstatsFields = [];
          roomstatsFields.push({ key: "roomLabel", label: this.$t('Room'), sortable: true });
          roomstatsFields.push({ key: "totalFalls", label: this.$t('Estimated Fall Count'), sortable: true });
          roomstatsFields.push({ key: "fallRate", label: this.$t('Fall Rate'), sortable: true });
         // roomstatsFields.push({ key: "totalAlerts", label: this.$t('Fall Alert Count'), sortable: true });
          roomstatsFields.push({ key: "totalBedExit", label: this.$t('Bed Exit Alert Count'), sortable: true });
          return roomstatsFields;                
      },

      timeRange: {
      // getter
      get: function () {
        return  this.$store.state.fleet.filter.timeRangeChoice;
      },
      // setter
      set: function (newValue) {
         //this.$store.commit('fleet/SET_ROOM_FILTER_TIMERANGECHOICE', newValue)        
      },
    },
    customeStart: {
      get: function() { 
        let v = this.$store.state.fleet.filter.dateFilter.start;        
        return  v;
        },
      set: function(val) { }
    },
    customeEnd: {
      get: function() {return this.$store.state.fleet.filter.dateFilter.end },
      set: function(val) { }
    },


  },
  mounted() {    
  },
  methods: {

  }
};
</script>


<style lang="scss" scoped>


</style>