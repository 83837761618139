<template>
  
    <b-modal
      hide-footer
      v-model="showNotificationHistory"
      :title="$t('Event notification details')"
      @hidden="close"      
      >
        
        <div class="wrapper">
        <div class="row">
        <b-col cols="12">          
          <span>{{ $t('Room:') }}</span> <span style="color: black;">{{$store.state.fleet.watchingNotificationHistoryData ? $store.state.fleet.watchingNotificationHistoryData.room : ''}}</span><br/>
          <span>{{ $t('Type:') }}</span> <span style="color: black;">{{$store.state.fleet.watchingNotificationHistoryData ? ($store.state.fleet.watchingNotificationHistoryData.type === 4 ? 'Bed Exit' : 'Fall') : ''}}</span><br/>
          <span>{{ $t('Event Date:') }}</span><span style="color: black;">{{$store.state.fleet.watchingNotificationHistoryData ? momentutc($store.state.fleet.watchingNotificationHistoryData.date).local().format('YYYY-MM-DD HH:mm:ss') : ''}}</span><br/><br/>
        </b-col>
        <b-col cols="12" v-if="$store.state.fleet.watchingNotificationHistoryData">
        
          <template v-for="(t,index) in twlist">
            <div v-if="!t.detection && t.To && !t.To.startsWith('+230')"><span :key="t.Timestamp+'_'+index" style="color: black;">at {{momentutc(t.Timestamp).format('HH:mm:ss')}}</span> <span style="color: grey">({{t.To}})</span> {{ $t('status is') }} <span style="font-weight: bold">{{ $t(t.CallStatus) }}</span> <span v-if="t.CallDuration && t.CallDuration > 0">({{$t('duration: ')}} {{t.CallDuration}}s)</span><br/></div>
            <div v-if="t.detection"><span :key="'e'+t.Timestamp+'_'+index" style="color: black;">at {{momentutc(t.Timestamp).format('HH:mm:ss')}}</span><span :key="t.Timestamp" style="color: darkgreen;"> {{$t('Detection event occured')}}</span></div>
          </template>
        </b-col>
        
        </div>
        </div>
      
    </b-modal>  
</template>

<script>
import moment from 'moment';
import { SPService } from '@/services/sp.service.js'

export default  {
  components: {
  },
  mounted() {
    this.$store.dispatch('fleet/watchNotificationhistory',null);
  },
  data() {
    return {
      showNotificationHistory: false
    }
  },
  watch : {
    '$store.state.fleet.watchingNotificationHistory': function(val) {      
      if(val === false)
        this.showNotificationHistory = false
     else {
        this.showNotificationHistory = val != null || val === true
        SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'report_watchnotificationhistory',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
     }
      }
  },
  computed: {
    show() {
       return this.$store.state.fleet.watchingNotificationHistoryData
    },
    twlist() {
      let events = [];
      if(this.$store.state.fleet.watchingNotificationHistoryData.children && this.$store.state.fleet.watchingNotificationHistoryData.children.length > 0)
      {
          
          for(let a of this.$store.state.fleet.watchingNotificationHistoryData.children)
          {
            events.push({ Timestamp: a.date, detection: true })
            events.push(...a.twilio_list);
          }
            
      }
      else {
        events.push({ Timestamp: this.$store.state.fleet.watchingNotificationHistoryData.date, detection: true })
        if(this.$store.state.fleet.watchingNotificationHistoryData.twilio_list) events.push(...this.$store.state.fleet.watchingNotificationHistoryData.twilio_list);
      }
      return events;

    }
  },
  methods: {
    moment,
    momentutc: moment,
    close() {
      this.$store.dispatch('fleet/watchNotificationhistory',null)
    },
  },
  name: "ISANotificationHistory"
}
</script>

<style lang="scss">
.isapreviewimg {
  width: 100%
}
</style>
