<template>

    <b-modal hide-footer size="xl"
             v-model="watchingSensorConnectivityHistory"
             :title="$t('Sensor Connexion events')"
             @hidden="close">

        <div class="wrapper">
            <div class="row">
                <b-col cols="12">
                    <span>{{ $t('Room:') }}</span> <span style="color: black;">{{$store.state.fleet.watchingSensorConnectivityHistoryData ? $store.state.fleet.watchingSensorConnectivityHistoryData.room_label : ''}}</span><br />                    
                </b-col>
                <b-col cols="12" v-if="$store.state.fleet.watchingSensorConnectivityHistoryData">
                    <template v-for="(d,index) of $store.state.fleet.watchingSensorConnectivityHistoryData.downEvents">
                        <div >
                          <span :key="d.start+'_'+index" style="color: black;">
                            {{moment(d.start).tz(timezone).format('YYYY/MM/DD')}} - {{moment(d.start).tz(timezone).format('HH:mm:ss')}}: {{$t('Down during')}} {{humanizeTime(d.start,d.end)}}
                          </span>
                          <br/>
                          <span style="color: grey"> 
                            {{$t('From')}} {{moment(d.start).tz(timezone).format('YYYY/MM/DD HH:mm:ss')}} {{$t('to')}} {{moment(d.end).tz(timezone).format('YYYY/MM/DD HH:mm:ss')}}
                          </span>
                          <br /><br />
                        </div>                        
                    </template>
                </b-col>

            </div>
        </div>

    </b-modal>
</template>

<script>
    import moment from 'moment-timezone';

    export default {
        components: {
        },
        mounted() {
            this.$store.dispatch('fleet/watchSensorConnectionHistory', null);
            this.timezone = this.$store.state.fleet.roomsetList[0].timezone ? this.$store.state.fleet.roomsetList[0].timezone : "Europe/Brussels";
        },
        data() {
            return {
                watchingSensorConnectivityHistory: false,
                timezone: ""
            }
        },
        watch: {
            '$store.state.fleet.watchingSensorConnectivityHistory': function (val) {
                if (val === false)
                    this.watchingSensorConnectivityHistory = false
                else
                    this.watchingSensorConnectivityHistory = val != null || val === true
            }
        },
        computed: {
            show() {
                return this.$store.state.fleet.watchingNotificationHistoryData
            },           
        },
        methods: {
            moment: moment,
            humanizeTime(diff, d2) {
                if(d2) {
                  diff = moment(d2).tz(this.timezone).diff(moment(diff).tz(this.timezone), 'seconds')
                }
                const tM = (30 * 24 * 60 * 60 );      
                const tD = ( 1 * 24 * 60 * 60 );
                const tH = (1 * 1 * 60 * 60 );
                const tm = (1 * 1 * 1 * 60 );
                const ts = (1 * 1 * 1 * 1 );      

                const diffM = Math.floor((diff) / tM);
                const diffD = Math.floor((diff-diffM*tM) / tD);      
                const diffH = Math.floor((diff-diffM*tM - diffD*tD) / tH);
                const diffm = Math.floor((diff-diffM*tM - diffD*tD - diffH*tH) / tm);
                const diffS = Math.floor((diff-diffM*tM - diffD*tD - diffH*tH - diffm*tm) / ts);
                
                return   (diffM > 0? `${diffM} ${this.$t('months')} ` : '')
                                    +  (diffD > 0? `${diffD} ${this.$t('days')} ` : '')
                                    +  (diffH > 0? `${diffH} ${this.$t('hours')} ` : '')
                                    +  (diffm > 0? `${diffm} ${this.$t('minutes')} ` : '')
                                    +  (diffS > 0? `${diffS} ${this.$t('seconds')}` : '');
              },
            close() {
                this.$store.dispatch('fleet/watchSensorConnectionHistory', null)
            },
        },
        name: "ISASensorConnectivityHistory"
    }
</script>

<style lang="scss">
    .isapreviewimg {
        width: 100%
    }
</style>
