<template>
  <div>
  <div v-if="!liveReportAvailable && $store.state.user.currentUser.organizationLabel === 'mintt'">
    <h1 style="color: red;">Customer has no subscription for reporting, he cannot see this! Your access has been logged.</h1>
  </div>
  <div v-if="!liveVideoAvailable && $store.state.user.currentUser.organizationLabel === 'mintt'">
    <h1 style="color: red;">Customer has no subscription to access video, he cannot see this! Your accesses to video will be logged.</h1>
  </div>
  <div v-if="!canAccessMedicalData() && (liveReportAvailable || $store.state.user.currentUser.organizationLabel === 'mintt')">
     
    <b-row>
      <b-col>
        <div class="not-found-wrap text-center">
          <h1 class="text-25">
            {{ $t('Access Denied') }}
          </h1>
          <p class="text-36 subheading mb-3">{{ $t("You don't have enough privileges to access to insights.")}}</p>
          <p class="mb-5 text-muted text-18">
            {{ $t('You need medical role, or medical administrator role to access this page. If you think that you should access to this page, please contact your administrator or mintt support at ') }} <a href="mailto:support@mintt.care">support@mintt.care</a>
          </p>
          <a class="btn btn-lg btn-primary btn-rounded" href="/">{{ $t('Go back to home') }}</a
          >
        </div>
      </b-col>
    </b-row>
  </div>
  <div v-if="canAccessMedicalData() && (liveReportAvailable || $store.state.user.currentUser.organizationLabel === 'mintt')">
    <ISANotificationHistoryModal />
    <ISAVideoWatcherModal />

    <b-tabs pills card class="isatab" v-model="activeTab">      
        <b-tab :sactive="activeTab === 0" :title="$store.state.user.isMobile ? $t('ISA') : $t('ISA Summary')" class="isatab">
            <ISAFallSummary/>                        
        </b-tab>                                             
       <b-tab :sactive="activeTab === 1" :title="$store.state.user.isMobile ? $t('CQI') : $t('Continuous Quality Improvement')" class="isatab">              
            <PAQS/> 
        </b-tab>    
<!--
        <b-tab :sactive="activeTab === 5" :title="$store.state.user.isMobile ? $t('Adm') : $t('Admissions')" class="isatab">              
            <PAdmissions/> 
        </b-tab>    
-->                     
        <b-tab :sactive="activeTab === 2" lazy :title="$store.state.user.isMobile ? $t('Alerts') : $t('Alerts Details & Videos')" class="isatab">
            <ISAReportFilter/>
            <ISAInsights/>            
            <ISAAlertList/>    
        </b-tab>      
        <b-tab :sactive="activeTab === 3" :title="$store.state.user.isMobile ? $t('Rooms') : $t('Rooms & Alert Rate')" class="isatab">              
            <ISAPerRoomList/>  
        </b-tab>                  
        <b-tab :sactive="activeTab === 4" v-if="canAdministrate" :title="$store.state.user.isMobile ? $t('Net.') : $t('Connectivity Summary')" class="isatab">                          
            <ISAInfraSummary/>                        
        </b-tab>       
    </b-tabs>

            
  </div>
  <div v-else>
    <b-row>
      <b-col>
        <div class="not-found-wrap text-center">
          <h1 class="text-25">
            {{ $t('Feature not subscribed') }}
          </h1>
          <p class="text-36 subheading mb-3">{{ $t("This feature is not subscribed and then not activated")}}</p>
          <p class="mb-5 text-muted text-18">
            {{ $t('Please contact MintT if you want to activate this feature.') }} <a href="mailto:support@mintt.care">support@mintt.care</a>
          </p>
          <a class="btn btn-lg btn-primary btn-rounded" href="/" id="gotohome">{{ $t('Go back to home') }}</a
          >
        </div>
      </b-col>
    </b-row>
  </div>
  </div>
</template>

<script>
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import { AuthService } from "@/services/auth.service";
import { SPService } from "@/services/sp.service";
import moment from "moment";
import ISAVideoWatcherModal from "@/components/ISA/report/ISAVideoWatcherModal";
import ISANotificationHistoryModal from "@/components/ISA/report/ISANotificationHistoryModal";
import ISAInsights from "@/components/ISA/report/ISAInsights";
import ISAReportFilter from "@/components/ISA/report/ISAReportFilter";
import ISAAlertList from "@/components/ISA/report/ISAAlertList";
import ISAPerRoomList from "@/components/ISA/report/ISAPerRoomList";
import ISAFallSummary from "@/components/ISA/report/ISAFallSummary";
import ISAInfraSummary from "@/components/ISA/report/ISAInfraSummary";
import PAQS from "@/components/ISA/report/PAQS";
import PAdmissions from '@/components/ISA/report/ISAPAdmissions'

export default {
  name: "FallReport",
  components: {
    ISANotificationHistoryModal,
    ISAVideoWatcherModal,
    ISAInsights,
    ISAReportFilter,
    ISAAlertList,    
    ISAPerRoomList,
    ISAFallSummary,
    ISAInfraSummary,
    PAQS,
    PAdmissions,
  },
  mounted() {        
    this.$store.dispatch('fleet/watchVideo',null)
    this.loadFallEvents();        
    if(this.$attrs && this.$attrs.videolink) {     
      const self = this;   
      setTimeout(() => {
          self.activeTab = 2       
          self.viewVideo(this.$attrs.videolink)       
      },500);      
    }
  },
  created() {
    const self = this;
    this.loadInterval = setInterval(self.loadFallEvents.bind(self), 60 * 1000);
  },
  data() {
    return {
      activeTab: 0,
      filter: {
        dateFilter: {},
        roomFilterValues: [],
        eventFilterValues: [],
        allRoomSelected: false,
        allEventSelected: false,
        showND: true,
        showFA: false,
        showTD: true,
        showUN: true,
        showTEST: false,        
      },
      dateFilterMinDate: moment().add(-6, "months").toISOString(),
      dateFilterMaxDate: moment().toISOString(),
      dateFilterCustomRanges: [
        { key: "thisWeek", label: "This week", value: "isoWeek" },
        { key: "lastWeek", label: "Last week", value: "-isoWeek" },
        { key: "last7Days", label: "Last 7 days", value: 7 },
        { key: "last15Days", label: "Last 15 days", value: 15 },
        { key: "last30Days", label: "Last 30 days", value: 30 },
        { key: "thisMonth", label: "This month", value: "month" },
        { key: "lastMonth", label: "Last month", value: "-month" },
        {
          key: "last3Months",
          label: "Last 3 months",
          value: () => {
            return {
              start: moment().add(-3, "months"),
              end: moment(),
            };
          },
        },
        {
          key: "last6Months",
          label: "Last 6 months",
          value: () => {
            return {
              start: moment().add(-6, "months"),
              end: moment(),
            };
          },
        },
      ],
      usageStatistics: false,

      fields: [
        { key: "date", sortable: true },
        { key: "roomset", label: "Care Unit", sortable: true },
        { key: "room", sortable: true },
        { key: "type", sortable: true },
        { key: "detection", sortable: true },
        { key: "video", sortable: true },
        { key: "notifications", sortable: true },
      ],
      falls: [],
      isBusy: false,
      perPage: 4000,
      page: 1,
      totalFalls: 0,
      to: 0,
      loadInterval: null,
            
    };
  },
  watch: {
    dateFilter: function () {},
    roomFilterValues: function () {},
    eventFilterValues: function () {},    
    "$store.state.fleet.currentOrganization": async function (val, oldVal) {      
      await this.loadFallEvents();      
    },
    "$store.state.fleet.error": async function (val, oldVal) {            
        if(val)
        {
          this.$bvToast.toast( val, {
              title: this.$t('Insights'),            
              solid: true,
              variant: 'warning',
              duration: 5000
            });
          this.$store.commit('fleet/SET_ERROR', null);
        }
          

    },
    activeTab: function(val) {
        let t = '???'
        switch(val) {
          case 1: t = 'SumUp'; break;
          case 1: t = 'CQI'; break;
          case 2: t = 'Alerts'; break;
          case 3: t = 'Rooms'; break;
          case 4: t = 'Infra'; break;          
          case 5: t = 'Adm'; break;         
        }

        const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'navigate',
          path: '/reporting/' + t,
          data: window.location.href,
        }
        
        SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
    }
  },
  computed: {
      liveReportAvailable() {        
           let mr = this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization && p.label.charAt(0) !== '_');           
           let allowed = false;
           for(let rs of mr) {
             if(rs.licensing && rs.licensing.liveReporting) {
               allowed = true;
             }
           }
           return allowed;
      },   
      
      liveVideoAvailable() {
           let mr = this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === this.$store.state.fleet.currentOrganization && p.label.charAt(0) !== '_');
           let allowed = false;
           for(let rs of mr) {
             if(rs.licensing && rs.licensing.videoAccess) {
               allowed = true;
             }
           }
           return allowed;
      },   

      canAdministrate()  {
        return AuthService.canAdministrate(this.$store.state.user.currentUser.role);
      }

  },
  methods: {
    moment,    
    async loadFallEvents() {      
       if(this.$store.state.user.currentUser.role !== 0)
          await this.$store.dispatch("fleet/fillReportReload");
    },
    
    async viewVideo(captureOid, attempt) {
      const self = this;
      if(!attempt) attempt = 0;

      let alert = null;
      if(this.$store.state.fleet.alertList) {        
        alert = this.$store.state.fleet.alertList.find(p => p.video === captureOid)                
      } 
      if(alert)
          this.$store.dispatch('fleet/watchVideo', alert);
      else 
      {
        const self = this;
         SPService.getVideoInfo(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, captureOid).then(async (result) => {            
            
            if(result && result.data.roomset_organization_label !== this.$store.state.fleet.currentOrganization) {
              await self.$store.commit('fleet/SET_CURRENT_ORGANIZATION',result.data.roomset_organization_label)              
            }
            
            let alert = result.data;
            
            alert.video = captureOid;              
            self.viewVideo(captureOid, attempt);
            self.$store.dispatch('fleet/watchVideo', alert);
         }).catch((err) => {
           
         })
        //attempt++;
        //if(attempt < 10)
        //  setTimeout((() => this.viewVideo(captureOid, attempt)).bind(this),200);
      }         
        
    },
    canAccessMedicalData()  {
      return AuthService.canAccessMedicalData(this.$store.state.user.currentUser.role);
    }
  },
};
</script>

<style lang="scss" scoped>
.b-table {
  .table {
    td {
      vertical-align: middle;
    }
  }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #75b0a2c0;
}

.apexcharts-yaxis-label {
  font-size: 1rem;
}

.isatab {
  padding-bottom: 0px;
  padding-top: 0px
}
</style>
